export enum OrderBillActionTypes {
    API_RESPONSE_SUCCESS = '@@orderBill/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@orderBill/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@orderBill/API_RESPONSE_VALIDATION_ERRORS',

    GET_BILLS = '@@orderBill/GET_BILLS',
    GET_BILL = '@@orderBill/GET_BILL',

    OPEN_SEARCH = '@@orderBill/OPEN_SEARCH',
    CLOSE_SEARCH = '@@orderBill/CLOSE_SEARCH',
    SEARCH_BILL = '@@orderBill/SEARCH_BILL',
    CLEAR_SEARCH = '@@orderBill/CLEAR_SEARCH',

    RESET = '@@orderBill/RESET',

    EXPORT = '@@orderBill/EXPORT',
    RESET_SEARCH = '@@orderBill/RESET_SEARCH',
}

export enum OrderBillErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



