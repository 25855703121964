import {EduExpenseActionTypes} from './constants';

export type EduExpenseActionType = {
    type:
        | EduExpenseActionTypes.API_RESPONSE_SUCCESS
        | EduExpenseActionTypes.API_RESPONSE_ERROR
        | EduExpenseActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | EduExpenseActionTypes.RESET
        | EduExpenseActionTypes.ORDER
        | EduExpenseActionTypes.CREATE
        | EduExpenseActionTypes.UPDATE
        | EduExpenseActionTypes.SEARCH
        | EduExpenseActionTypes.REVIEW
        | EduExpenseActionTypes.CANCEL
        | EduExpenseActionTypes.UPDATE_SETTING
        | EduExpenseActionTypes.GET_SETTING;

    payload: {} | string;
};

type EduExpense = {
    id: number;
    name: string;
};

class EduExpenseActions {
    apiResponseSuccess = (actionType: string, data: EduExpense | {}): EduExpenseActionType => ({
        type: EduExpenseActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string, trackId: string = ""): EduExpenseActionType => ({
        type: EduExpenseActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error, trackId },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: [], trackId: string = ""): EduExpenseActionType => ({
        type: EduExpenseActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors, trackId },
    });

    reset = (): EduExpenseActionType => ({
        type: EduExpenseActionTypes.RESET,
        payload: {},
    });

    getEduExpense = (queryParams: any): EduExpenseActionType => ({
        type: EduExpenseActionTypes.ORDER,
        payload: { queryParams },
    });

    create = (data: any): EduExpenseActionType => ({
        type: EduExpenseActionTypes.CREATE,
        payload: { data },
    });

    update = (data: any): EduExpenseActionType => ({
        type: EduExpenseActionTypes.UPDATE,
        payload: { data },
    });

    search = (queryParams: any): EduExpenseActionType => ({
        type: EduExpenseActionTypes.SEARCH,
        payload: { queryParams },
    });

    review = (data: any): EduExpenseActionType => ({
        type: EduExpenseActionTypes.REVIEW,
        payload: { data },
    });

    cancel = (hashedId: any): EduExpenseActionType => ({
        type: EduExpenseActionTypes.CANCEL,
        payload: { hashedId },
    });

    updateSetting = (data: any, trackId: string): EduExpenseActionType => ({
        type: EduExpenseActionTypes.UPDATE_SETTING,
        payload: { data, trackId },
    });

    getSetting = (): EduExpenseActionType => ({
        type: EduExpenseActionTypes.GET_SETTING,
        payload: {},
    });
}

const eduExpenseActions = new EduExpenseActions();
export { eduExpenseActions };
