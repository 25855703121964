import { OrderTransactionActionTypes } from './constants';
import {isEmptyObject} from "../../helpers/functions";

const INIT_STATE = {
    transaction: {},

    transactions: {},
    queryParams: {},

    loading: false,
    error: "",
    validationErrors: {},

    searchModal: false,
    tmpTransactions: {},
    tmpQueryParams: {},
    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {},

    exportUrl: "",
    exportLoading: false,
    exportError: "",
    exportValidationErrors: [],
};

type OrderTransactionData = {
    id: number;
    name: string;
    guard_name: string;
};

type OrderTransactionQueryParams = {
    limit: number;
    page: number;
    orderBy: string;
    sort: string;
    transactionNames: string;
};

type OrderTransactionsResult = {
    transactions: OrderTransactionData | {},
    queryParams: OrderTransactionQueryParams
}

type OrderTransactionActionType = {
    type:
        | OrderTransactionActionTypes.API_RESPONSE_SUCCESS
        | OrderTransactionActionTypes.API_RESPONSE_ERROR
        | OrderTransactionActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | OrderTransactionActionTypes.GET_TRANSACTIONS
        | OrderTransactionActionTypes.GET_TRANSACTION
        | OrderTransactionActionTypes.RESET
        | OrderTransactionActionTypes.EXPORT
        | OrderTransactionActionTypes.RESET_SEARCH

        | OrderTransactionActionTypes.OPEN_SEARCH
        | OrderTransactionActionTypes.CLOSE_SEARCH
        | OrderTransactionActionTypes.SEARCH_TRANSACTION
        | OrderTransactionActionTypes.CLEAR_SEARCH;
    payload: {
        actionType?: string;
        data?: OrderTransactionsResult | any;
        error?: string;
        validationErrors?: [];
    };
};

type State = {
    transactions?: OrderTransactionData | {};
    queryParams?: {};

    loading?: boolean;
    value?: boolean;
    tmpTransactions?: any;
    tmpQueryParams?: any;
};

const OrderTransaction = (state: State = INIT_STATE, action: OrderTransactionActionType) => {
    switch (action.type) {
        case OrderTransactionActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case OrderTransactionActionTypes.GET_TRANSACTIONS: {
                    return {
                        ...state,
                        transactions: action.payload.data?.transactions,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case OrderTransactionActionTypes.GET_TRANSACTION: {
                    return {
                        ...state,
                        transaction: action.payload.data,
                        loading: false,
                    };
                }
                case OrderTransactionActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpTransactions: isEmptyObject(state.tmpTransactions)? state.transactions: state.tmpTransactions,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        transactions: action.payload.data?.transactions,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                case OrderTransactionActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportUrl: action.payload.data,
                        exportLoading: false,

                        searchMode: false,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case OrderTransactionActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case OrderTransactionActionTypes.GET_TRANSACTION:
                case OrderTransactionActionTypes.GET_TRANSACTIONS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case OrderTransactionActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                case OrderTransactionActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportError: action.payload.error,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case OrderTransactionActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case OrderTransactionActionTypes.GET_TRANSACTIONS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case OrderTransactionActionTypes.GET_TRANSACTION: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case OrderTransactionActionTypes.SEARCH_TRANSACTION: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                case OrderTransactionActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportValidationErrors: action.payload.validationErrors,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case OrderTransactionActionTypes.GET_TRANSACTIONS:
            return {
                ...state,
                // transactions: {},
                loading: true,
                error: "",
            };
        case OrderTransactionActionTypes.GET_TRANSACTION:
            return {
                ...state,
                transaction: {},
                loading: true,
                error: "",
            };

        case OrderTransactionActionTypes.RESET:
            return {
                ...state,
                transaction: {},

                transactions: {},
                queryParams: {},

                loading: false,
                error: "",
                validationErrors: {},

                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpTransactions: {},
                tmpQueryParams: {},

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            };


        // search actions
        case OrderTransactionActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case OrderTransactionActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case OrderTransactionActionTypes.SEARCH_TRANSACTION:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case OrderTransactionActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                transactions: state.tmpTransactions,
                queryParams: state.tmpQueryParams,
                tmpTransactions: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        case OrderTransactionActionTypes.EXPORT:

            return {
                ...state,
                exportUrl: "",
                exportLoading: true,
                exportError: "",
                exportValidationErrors: [],
            };

        case OrderTransactionActionTypes.RESET_SEARCH:

            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            }

        default:
            return { ...state };
    }
};

export default OrderTransaction;
