import {IntlMoneyTransferActionTypes} from './constants';

export type IntlMoneyTransferActionType = {
    type:
        | IntlMoneyTransferActionTypes.API_RESPONSE_SUCCESS
        | IntlMoneyTransferActionTypes.API_RESPONSE_ERROR
        | IntlMoneyTransferActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | IntlMoneyTransferActionTypes.RESET
        | IntlMoneyTransferActionTypes.ORDER
        | IntlMoneyTransferActionTypes.CREATE
        | IntlMoneyTransferActionTypes.UPDATE
        | IntlMoneyTransferActionTypes.SEARCH
        | IntlMoneyTransferActionTypes.REVIEW
        | IntlMoneyTransferActionTypes.CANCEL
        | IntlMoneyTransferActionTypes.UPDATE_SETTING
        | IntlMoneyTransferActionTypes.GET_SETTING;

    payload: {} | string;
};

type IntlMoneyTransfer = {
    id: number;
    name: string;
};

class IntlMoneyTransferActions {
    apiResponseSuccess = (actionType: string, data: IntlMoneyTransfer | {}): IntlMoneyTransferActionType => ({
        type: IntlMoneyTransferActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string, trackId: string = ""): IntlMoneyTransferActionType => ({
        type: IntlMoneyTransferActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error, trackId },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: [], trackId: string = ""): IntlMoneyTransferActionType => ({
        type: IntlMoneyTransferActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors, trackId },
    });

    reset = (): IntlMoneyTransferActionType => ({
        type: IntlMoneyTransferActionTypes.RESET,
        payload: {},
    });

    getIntlMoneyTransfer = (queryParams: any): IntlMoneyTransferActionType => ({
        type: IntlMoneyTransferActionTypes.ORDER,
        payload: { queryParams },
    });

    create = (data: any): IntlMoneyTransferActionType => ({
        type: IntlMoneyTransferActionTypes.CREATE,
        payload: { data },
    });

    update = (data: any): IntlMoneyTransferActionType => ({
        type: IntlMoneyTransferActionTypes.UPDATE,
        payload: { data },
    });

    search = (queryParams: any): IntlMoneyTransferActionType => ({
        type: IntlMoneyTransferActionTypes.SEARCH,
        payload: { queryParams },
    });

    review = (data: any): IntlMoneyTransferActionType => ({
        type: IntlMoneyTransferActionTypes.REVIEW,
        payload: { data },
    });

    cancel = (hashedId: any): IntlMoneyTransferActionType => ({
        type: IntlMoneyTransferActionTypes.CANCEL,
        payload: { hashedId },
    });

    updateSetting = (data: any, trackId: string): IntlMoneyTransferActionType => ({
        type: IntlMoneyTransferActionTypes.UPDATE_SETTING,
        payload: { data, trackId },
    });

    getSetting = (): IntlMoneyTransferActionType => ({
        type: IntlMoneyTransferActionTypes.GET_SETTING,
        payload: {},
    });
}

const intlMoneyTransferActions = new IntlMoneyTransferActions();
export { intlMoneyTransferActions };
