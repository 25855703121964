import { OrderActionTypes } from './constants';
import { isEmptyObject } from '../../helpers/functions';

const INIT_STATE = {
    error: "",
    loading: false,
    orders: {},
    order: {},
    inserted: false,
    updated: false,
    deleted: false,

    previewDetail: {},
    previewDetailLoading: false,
    previewDetailError: "",

    paymentStep1Done: false,
    paymentStep2Done: false,

    billCreated: false,
    billUpdated: false,

    rates: [],

    orderBills: [],
    orderBill: {},

    noteCreated: false,
};

type OrderActionType = {
    type:
        | OrderActionTypes.API_RESPONSE_SUCCESS
        | OrderActionTypes.API_RESPONSE_ERROR
        | OrderActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | OrderActionTypes.ORDERS
        | OrderActionTypes.ORDER
        | OrderActionTypes.CREATE
        | OrderActionTypes.UPDATE
        | OrderActionTypes.DELETE
        | OrderActionTypes.RESET
        | OrderActionTypes.RATES
        | OrderActionTypes.PREVIEW_PAYMENT_DETAIL
        | OrderActionTypes.RESET_PREVIEW_DETAIL
        | OrderActionTypes.PAYMENT_STEP_1
        | OrderActionTypes.PAYMENT_STEP_2
        | OrderActionTypes.CREATE_BILL
        | OrderActionTypes.UPDATE_BILL
        | OrderActionTypes.ORDER_BILLS
        | OrderActionTypes.ORDER_BILL
        | OrderActionTypes.CREATE_NOTE
        | OrderActionTypes.OPEN_SEARCH
        | OrderActionTypes.CLOSE_SEARCH
        | OrderActionTypes.SEARCH_ORDER
        | OrderActionTypes.CLEAR_SEARCH
        | OrderActionTypes.EXPORT
        | OrderActionTypes.RESET_SEARCH;

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];

        key: string;
        value?: string;

        page?: string;
    };
};

type State = {
    loading: boolean,
    orders: any,
    queryParams?: {};

    tmpOrders?: any;
    tmpQueryParams?: any;
};

const Order = (state: State = INIT_STATE, action: OrderActionType) => {
    switch (action.type) {
        case OrderActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case OrderActionTypes.ORDERS: {
                    return {
                        ...state,
                        orders: action.payload.data?.orders,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }

                case OrderActionTypes.ORDER: {
                    return {
                        ...state,
                        order: action.payload.data,
                        loading: false,
                    };
                }

                case OrderActionTypes.SEARCH: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpOrders: isEmptyObject(state.tmpOrders)? state.orders: state.tmpOrders,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        orders: action.payload.data?.orders,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }

                case OrderActionTypes.CREATE: {
                    return {
                        ...state,
                        inserted: true,
                        loading: false,
                    };
                }

                case OrderActionTypes.UPDATE: {
                    return {
                        ...state,
                        updated: true,
                        loading: false,
                    };
                }

                case OrderActionTypes.DELETE: {
                    return {
                        ...state,
                        deleted: true,
                        loading: false,
                        orders: state.orders.filter(function(item: any) {
                            return item.id !== action.payload.data
                        })
                    };
                }
                case OrderActionTypes.RATES: {
                    return {
                        ...state,
                        loading: false,
                        rates: action.payload.data
                    };
                }
                case OrderActionTypes.PREVIEW_PAYMENT_DETAIL: {
                    return {
                        ...state,
                        previewDetail: action.payload.data,
                        previewDetailLoading: false,
                    };
                }
                case OrderActionTypes.PAYMENT_STEP_1: {
                    return {
                        ...state,
                        loading: false,
                        paymentStep1Done: action.payload.data,
                    };
                }
                case OrderActionTypes.PAYMENT_STEP_2: {
                    return {
                        ...state,
                        loading: false,
                        paymentStep2Done: action.payload.data,
                    };
                }
                case OrderActionTypes.CREATE_BILL: {
                    return {
                        ...state,
                        loading: false,
                        billCreated: action.payload.data,
                    };
                }
                case OrderActionTypes.UPDATE_BILL: {
                    return {
                        ...state,
                        loading: false,
                        billUpdated: action.payload.data,
                    };
                }
                case OrderActionTypes.ORDER_BILLS: {
                    return {
                        ...state,
                        orderBills: action.payload.data,
                        loading: false,
                    };
                }

                case OrderActionTypes.ORDER_BILL: {
                    return {
                        ...state,
                        orderBill: action.payload.data,
                        loading: false,
                    };
                }
                case OrderActionTypes.CREATE_NOTE: {
                    return {
                        ...state,
                        loading: false,
                        noteCreated: action.payload.data,
                    };
                }
                case OrderActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportUrl: action.payload.data,
                        exportLoading: false,

                        searchMode: false,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case OrderActionTypes.API_RESPONSE_ERROR:

            switch (action.payload.actionType) {

                case OrderActionTypes.ORDERS:
                case OrderActionTypes.ORDER:
                case OrderActionTypes.CREATE:
                case OrderActionTypes.UPDATE:
                case OrderActionTypes.DELETE:
                case OrderActionTypes.RATES:
                case OrderActionTypes.PAYMENT_STEP_1:
                case OrderActionTypes.PAYMENT_STEP_2:
                case OrderActionTypes.CREATE_BILL:
                case OrderActionTypes.UPDATE_BILL:
                case OrderActionTypes.ORDER_BILLS:
                case OrderActionTypes.ORDER_BILL:
                case OrderActionTypes.CREATE_NOTE:
                {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case OrderActionTypes.SEARCH: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                case OrderActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportError: action.payload.error,
                        exportLoading: false,
                    };
                }
                case OrderActionTypes.PREVIEW_PAYMENT_DETAIL:
                {
                    return {
                        ...state,
                        previewDetailError: action.payload.error,
                        previewDetailLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case OrderActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case OrderActionTypes.ORDERS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case OrderActionTypes.ORDER:
                case OrderActionTypes.CREATE:
                case OrderActionTypes.UPDATE:
                case OrderActionTypes.DELETE:
                case OrderActionTypes.RATES:
                case OrderActionTypes.PREVIEW_PAYMENT_DETAIL:
                case OrderActionTypes.PAYMENT_STEP_1:
                case OrderActionTypes.PAYMENT_STEP_2:
                case OrderActionTypes.CREATE_BILL:
                case OrderActionTypes.UPDATE_BILL:
                case OrderActionTypes.ORDER_BILLS:
                case OrderActionTypes.ORDER_BILL:
                case OrderActionTypes.CREATE_NOTE:
                {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case OrderActionTypes.SEARCH: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                case OrderActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportValidationErrors: action.payload.validationErrors,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case OrderActionTypes.ORDERS:
            return {
                ...state,
                loading: true,
                error: "",
            };
        case OrderActionTypes.ORDER:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case OrderActionTypes.CREATE:
            return {
                ...state,
                inserted: false,
                loading: true,
                error: "",
            };
        case OrderActionTypes.UPDATE:
            return {
                ...state,
                inserted: false,
                loading: true,
                error: "",
            };
        case OrderActionTypes.DELETE:
            return {
                ...state,
                deleted: false,
                loading: true,
                error: "",
            };

        case OrderActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: "",

                orders: {},
                order: {},
                inserted: false,
                updated: false,
                deleted: false,

                previewDetail: {},
                previewDetailLoading: false,
                previewDetailError: "",

                rates: [],
                paymentStep1Done: false,
                paymentStep2Done: false,

                billCreated: false,
                billUpdated: false,
                orderBills: [],
                orderBill: {},

                noteCreated: false,

                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpOrders: {},
                tmpQueryParams: {},

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            };

        case OrderActionTypes.RATES:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case OrderActionTypes.PREVIEW_PAYMENT_DETAIL:
            return {
                ...state,
                previewDetail: {},
                previewDetailLoading: true,
                previewDetailError: "",
            };

        case OrderActionTypes.RESET_PREVIEW_DETAIL:
            return {
                ...state,
                previewDetail: {},
                previewDetailLoading: false,
                previewDetailError: "",
            };

        case OrderActionTypes.PAYMENT_STEP_1:
            return {
                ...state,
                paymentStep1Done: false,
                loading: true,
                error: "",
            };

        case OrderActionTypes.PAYMENT_STEP_2:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case OrderActionTypes.CREATE_BILL:
            return {
                ...state,
                loading: true,
                error: "",
            };

        case OrderActionTypes.UPDATE_BILL:
            return {
                ...state,
                loading: true,
                error: "",
            };
        case OrderActionTypes.ORDER_BILLS:
            return {
                ...state,
                loading: true,
                error: "",
            };
        case OrderActionTypes.ORDER_BILL:
            return {
                ...state,
                loading: true,
                error: "",
            };
        case OrderActionTypes.CREATE_NOTE:
            return {
                ...state,
                loading: true,
                error: "",
            };

        // search actions
        case OrderActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case OrderActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case OrderActionTypes.SEARCH_ORDER:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case OrderActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                transactions: state.tmpOrders,
                queryParams: state.tmpQueryParams,
                tmpOrders: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        case OrderActionTypes.EXPORT:

            return {
                ...state,
                exportUrl: "",
                exportLoading: true,
                exportError: "",
                exportValidationErrors: [],
            };

        case OrderActionTypes.RESET_SEARCH:

            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            }

        default:
            return { ...state };
    }
};

export default Order;
