import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {EduExpenseActionTypes, EduExpenseErrors} from "./constants";
import {eduExpenseActions} from "./actions";
import {graphql} from "../../helpers";
import {SagaIterator} from "@redux-saga/core";
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";

type EduExpenseData = {
    payload: {
        id: string;

        limit: string;
        page: string;

        queryParams: {
            limit: string;
            page: string;
            eduExpenseBy: string;
            sort: string;
        };

        data: any;
        trackId: string;
        hashedId: string;
    };
    type: string;
};

function* order({ payload: { queryParams } }: EduExpenseData): SagaIterator {
    try {
        const param = {
            query:`query OrderEduExpensePayment($hashedId: String!) {
                orderEduExpensePayment(hashedId: $hashedId) {
                    hashedId
                    user {
                        id
                        username
                    }
                    created_at
                    updated_at
                    amount
                    currency
                    url
                    data {
                        username
                        password
                    }
                    rate
                    rate_currency
                    fee
                    fee_currency
                    discount
                    discount_currency
                    name
                    final_amount
                    final_amount_currency
                    description
                    status
                    bills {
                        hashedId
                        amount
                        currency
                        status
                        created_at
                    }
                    notes {
                        note
                        created_at
                    }
                    transactions {
                        transaction {
                            transaction_id
                            thread_id
                            type
                            status
                            name
                            amountFloat
                            wallet {
                                name
                            }
                            created_at
                        }
                    }
                }
            }`,

            variables: {
                ...queryParams
            },
            operationName: "OrderEduExpensePayment"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, EduExpenseErrors.RESPONSE_200);

        const rEduExpense = response.data;

        checkServerError(rEduExpense);

        const order = pluckResponse(rEduExpense, "orderEduExpensePayment");

        yield put(eduExpenseActions.apiResponseSuccess(EduExpenseActionTypes.ORDER, order));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(eduExpenseActions.apiResponseError(EduExpenseActionTypes.ORDER, error));
        } else {
            yield put(eduExpenseActions.apiResponseValidationErrors(EduExpenseActionTypes.ORDER, error));
        }
    }
}

function* create({ payload: {data} }: EduExpenseData): SagaIterator {

    try {
        const param = {

            query:`mutation CreateEduExpensePayment(
                $amount: String!,
                $currency: String!,
                $url: String!,
                $username: String,
                $password: String,
                $acceptConditions: String!,
                $descriptions: String,
            ) {
                createEduExpensePayment(
                    amount: $amount,
                    currency: $currency,
                    url: $url,
                    username: $username,
                    password: $password,
                    acceptConditions: $acceptConditions,
                    descriptions: $descriptions,
                )
            }`,

            variables: { ...data },
            operationName: "CreateEduExpensePayment"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, EduExpenseErrors.RESPONSE_200);

        const rEduExpense = response.data;

        checkServerError(rEduExpense);

        const createEduExpense = pluckResponse(rEduExpense, "createEduExpensePayment");

        yield put(eduExpenseActions.apiResponseSuccess(EduExpenseActionTypes.CREATE, createEduExpense));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(eduExpenseActions.apiResponseError(EduExpenseActionTypes.CREATE, error));
        } else {
            yield put(eduExpenseActions.apiResponseValidationErrors(EduExpenseActionTypes.CREATE, error));
        }
    }
}

function* update({ payload: {data} }: EduExpenseData): SagaIterator {
    try {
        const param = {

            query:`mutation UpdateEduExpensePayment(
                $hashedId: String!,
                $amount: String!,
                $currency: String!,
                $url: String!,
                $username: String,
                $password: String,
                $descriptions: String,
                $status: String!,
                $note: String,
                $updateRate: Boolean,
            ) {
                updateEduExpensePayment(
                    hashedId: $hashedId,
                    amount: $amount,
                    currency: $currency,
                    url: $url,
                    username: $username,
                    password: $password,
                    descriptions: $descriptions,
                    status: $status,
                    note: $note,
                    updateRate: $updateRate,
                )
            }`,

            variables: { ...data },
            operationName: "UpdateEduExpensePayment"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, EduExpenseErrors.RESPONSE_200);

        const rEduExpense = response.data;

        checkServerError(rEduExpense);

        pluckResponse(rEduExpense, "updateEduExpense");

        yield put(eduExpenseActions.apiResponseSuccess(EduExpenseActionTypes.UPDATE, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(eduExpenseActions.apiResponseError(EduExpenseActionTypes.UPDATE, error));
        } else {
            yield put(eduExpenseActions.apiResponseValidationErrors(EduExpenseActionTypes.UPDATE, error));
        }
    }
}

function* cancel({ payload: {hashedId} }: EduExpenseData): SagaIterator {
    try {
        const param = {

            query:`mutation CancelEduExpensePayment(
                $hashedId: String!
            ) {
                cancelEduExpensePayment(
                    hashedId: $hashedId
                )
            }`,

            variables: {
                hashedId
            },
            operationName: "CancelEduExpensePayment"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, EduExpenseErrors.RESPONSE_200);

        const rEduExpense = response.data;

        checkServerError(rEduExpense);

        pluckResponse(rEduExpense, "cancelEduExpense");

        yield put(eduExpenseActions.apiResponseSuccess(EduExpenseActionTypes.CANCEL, hashedId));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(eduExpenseActions.apiResponseError(EduExpenseActionTypes.CANCEL, error));
        } else {
            yield put(eduExpenseActions.apiResponseValidationErrors(EduExpenseActionTypes.CANCEL, error));
        }
    }
}

function* review({ payload: { data } }: EduExpenseData): SagaIterator {
    try {
        const param = {
            query:`query EduExpensePaymentReview($amount: String!, $currency: String!) {
                eduExpensePaymentReview(amount: $amount, currency: $currency) {
                    amount
                    amount_currency
                    rate
                    rate_currency
                    fee
                    fee_currency
                    final_amount
                    final_amount_currency
                }
            }`,

            variables: { ...data },
            operationName: "EduExpensePaymentReview"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, EduExpenseErrors.RESPONSE_200);

        const rOPR = response.data;

        checkServerError(rOPR);

        const eduExpenseReview = pluckResponse(rOPR, "eduExpensePaymentReview");

        yield put(eduExpenseActions.apiResponseSuccess(EduExpenseActionTypes.REVIEW, eduExpenseReview));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(eduExpenseActions.apiResponseError(EduExpenseActionTypes.REVIEW, error));
        } else {
            yield put(eduExpenseActions.apiResponseValidationErrors(EduExpenseActionTypes.REVIEW, error));
        }
    }
}


function* updateEEPSetting({ payload: { data, trackId } }: EduExpenseData): SagaIterator {

    try {

        const paymentMethods = data.paymentMethods || [];

        // Transform the array of objects into separate arrays for each field
        const paymentMethod = paymentMethods.map((item: any) => item.paymentMethod);
        const currencyCat = paymentMethods.map((item: any) => item.currencyCat);
        const eduExpensePaymentFee = paymentMethods.map((item: any) => item.eduExpensePaymentFee);


        const param = {
            query:`mutation UpdateEduExpensePaymentSetting(
                $paymentMethod: [String!]
                $currencyCat: [String!]
                $eduExpensePaymentFee: [Float!]
                $eduExpensePaymentStatus: String!
            ) {
                updateEduExpensePaymentSetting(
                    paymentMethod: $paymentMethod
                    currencyCat: $currencyCat
                    eduExpensePaymentFee: $eduExpensePaymentFee
                    eduExpensePaymentStatus: $eduExpensePaymentStatus
                )
            }`,

            variables: {
                paymentMethod,
                currencyCat,
                eduExpensePaymentFee,
                eduExpensePaymentStatus: data?.eduExpensePaymentStatus,
            },

            operationName: "UpdateEduExpensePaymentSetting"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, EduExpenseErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        pluckResponse(responseCR, "updateEduExpensePaymentSetting");

        yield put(eduExpenseActions.apiResponseSuccess(EduExpenseActionTypes.UPDATE_SETTING, {
            result: true,
            trackId: trackId
        }));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(eduExpenseActions.apiResponseError(EduExpenseActionTypes.UPDATE_SETTING, error, trackId));
        } else {
            yield put(eduExpenseActions.apiResponseValidationErrors(EduExpenseActionTypes.UPDATE_SETTING, error, trackId));
        }
    }
}

function* getEEPSetting(): SagaIterator {

    try {
        const param = {
            query:`query EduExpensePaymentSetting {
                eduExpensePaymentSetting {
                    edu_expense_payment_status
                    edu_expense_payment_methods {
                        paymentMethod
                        currencyCat
                        eduExpensePaymentFee
                    }
                }
            }`,

            operationName: "EduExpensePaymentSetting"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, EduExpenseErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        const eduExpenseSetting = pluckResponse(responseCR, "eduExpensePaymentSetting");

        yield put(eduExpenseActions.apiResponseSuccess(EduExpenseActionTypes.GET_SETTING, eduExpenseSetting));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(eduExpenseActions.apiResponseError(EduExpenseActionTypes.GET_SETTING, error));
        } else {
            yield put(eduExpenseActions.apiResponseValidationErrors(EduExpenseActionTypes.GET_SETTING, error));
        }
    }
}

export function* watchOrder() {
    yield takeEvery(EduExpenseActionTypes.ORDER, order);
}

export function* watchInsert() {
    yield takeEvery(EduExpenseActionTypes.CREATE, create);
}

export function* watchUpdate() {
    yield takeEvery(EduExpenseActionTypes.UPDATE, update);
}

export function* watchCancel() {
    yield takeEvery(EduExpenseActionTypes.CANCEL, cancel);
}

export function* watchReview() {
    yield takeEvery(EduExpenseActionTypes.REVIEW, review);
}

export function* watchUpdateEEPSetting() {
    yield takeEvery(EduExpenseActionTypes.UPDATE_SETTING, updateEEPSetting);
}

export function* watchGetEEPSetting() {
    yield takeEvery(EduExpenseActionTypes.GET_SETTING, getEEPSetting);
}

function* eduExpenseSaga() {
    yield all([
        fork(watchOrder),
        fork(watchInsert),
        fork(watchUpdate),
        fork(watchCancel),
        fork(watchReview),
        fork(watchUpdateEEPSetting),
        fork(watchGetEEPSetting),
    ]);
}

export default eduExpenseSaga;
