export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemType[];
    permissions?: string[];
};

const MENU_ITEMS: MenuItemType[] = [
    {
        key: 'app-dashboard',
        label: 'Dashboard',
        isTitle: false,
        icon: 'uil-home-alt',
        // badge: { variant: 'success', text: '4' },
        url: '/dashboard',
        permissions: ["View Dashboard"],
    },
    // { key: 'apps', label: 'Apps', isTitle: true, permissions: ["Can View Deposits"] },
    {
        key: 'apps-users',
        label: 'Users',
        isTitle: false,
        icon: 'uil-users-alt',
        permissions: ["View Users"],
        children: [
            {
                key: 'app-users',
                label: 'Users',
                url: '/users/',
                parentKey: 'apps-users',
                permissions: ["View Users"]
            },
            {
                key: 'app-new-user',
                label: 'Create New User',
                url: '/users/create/',
                parentKey: 'apps-users',
                permissions: ["Create User"]
            },
        ],
    },
    {
        key: 'apps-verifications',
        label: 'Verifications',
        isTitle: false,
        icon: 'uil-users-alt',
        permissions: ["Approve Personal Detail"],
        children: [
            {
                key: 'app-auth-personal',
                label: 'Personal Detail',
                url: '/verification/personal-details/',
                parentKey: 'apps-verifications',
                permissions: ["Approve Personal Detail"]
            },
            {
                key: 'app-auth-mobile',
                label: 'Mobile Number',
                url: '/verification/mobile-numbers/',
                parentKey: 'apps-verifications',
                permissions: ["Approve Personal Detail"]
            },
            {
                key: 'app-auth-bank-card',
                label: 'Bank Card',
                url: '/verification/bank-cards/',
                parentKey: 'apps-verifications',
                permissions: ["Approve Personal Detail"]
            },
            {
                key: 'app-auth-terms-conditions',
                label: 'Terms And Conditions',
                url: '/verification/terms-and-conditions/',
                parentKey: 'apps-verifications',
                permissions: ["Approve Personal Detail"]
            },
        ],
    },
    {
        key: 'apps-roles',
        label: 'Roles & Permissions',
        isTitle: false,
        icon: 'uil-user-square',
        permissions: ["View Roles", "View Permissions"],
        children: [
            {
                key: 'app-roles',
                label: 'Roles',
                url: '/roles/',
                parentKey: 'apps-roles',
                permissions: ["View Roles"]
            },
            {
                key: 'app-new-roles',
                label: 'Create New Role',
                url: '/roles/create/',
                parentKey: 'apps-roles',
                permissions: ["Create Role"]
            },
            {
                key: 'app-permissions',
                label: 'Permissions',
                url: '/permissions/',
                parentKey: 'apps-roles',
                permissions: ["View Permissions"]
            },
        ],
    },
    // { key: 'OrderProductSec', label: 'Orders', isTitle: true, permissions: ["View Payment Requests"], },
    // {
    //     key: 'apps-orders',
    //     label: 'Order Product',
    //     isTitle: false,
    //     icon: 'uil-cart',
    //     permissions: ["View Purchases", "Create Purchase"],
    //     children: [
    //         {
    //             key: 'app-orders',
    //             label: 'All Purchases',
    //             url: '/purchases/',
    //             parentKey: 'apps-orders',
    //             permissions: ["View Purchases"]
    //         },
    //         {
    //             key: 'app-new-payment-request',
    //             label: 'New Request',
    //             url: '/purchases/request/',
    //             parentKey: 'apps-orders',
    //             permissions: ["Create Purchase"]
    //         },
    //     ],
    // },
    // { key: 'WalletSec', label: 'My Wallet', isTitle: true, permissions: ["View Deposits","View Wallets","View Wallets","View Send Transactions","View Receive Transactions","View Transactions", "View All USD Wallets", "View All IRR Wallets"], },

    {
        key: 'apps-wallets',
        label: 'Wallet',
        isTitle: false,
        icon: 'uil-money-stack',
        permissions: ["View Deposits","View USD Wallet", "View IRR Wallet"],
        children: [
            {
                key: 'app-wallets',
                label: 'Wallets',
                url: '/wallets/',
                parentKey: 'apps-wallets',
                permissions: ["View USD Wallet", "View IRR Wallet"],
            },
            {
                key: 'app-new-deposit',
                label: 'New Deposit',
                url: '/deposits/create/',
                parentKey: 'apps-wallets',
                permissions: ["Create IRR Deposit"]
            },
            {
                key: 'app-new-withdraw',
                label: 'New Withdraw',
                url: '/withdraws/create/',
                parentKey: 'apps-wallets',
                permissions: ["Create IRR Withdraw"]
            },
            {
                key: 'app-manual-withdraw',
                label: 'Manual Withdraw',
                url: '/withdraws/create/manual/',
                parentKey: 'apps-wallets',
                permissions: ["Create Manual Withdraw"]
            },
            {
                key: 'app-manual-deposit',
                label: 'Manual Deposit',
                url: '/deposits/create/manual/',
                parentKey: 'apps-wallets',
                permissions: ["Create Manual Deposit"]
            },
            {
                key: 'app-withdraws',
                label: 'All Withdraws',
                url: '/withdraws/',
                parentKey: 'apps-wallets',
                permissions: ["View All Withdraws"]
            },
            {
                key: 'app-deposits',
                label: 'All Deposits',
                url: '/deposits/',
                parentKey: 'apps-wallets',
                permissions: ["View All Deposits"]
            },
            {
                key: 'app-dws',
                label: 'All Transactions',
                url: '/deposits-withdraws/',
                parentKey: 'apps-wallets',
                permissions: ["View Deposits", "View Withdraws"]
            },


        ],
    },
    {
        key: 'apps-exchanges',
        label: 'Exchange',
        isTitle: false,
        icon: 'uil-refresh',
        permissions: ["View Exchanges", "Create Exchange"],
        children: [
            {
                key: 'app-new-exchange',
                label: 'New Exchange',
                url: '/exchanges/create/',
                parentKey: 'apps-exchanges',
                permissions: ["Create Exchange"]
            },
            {
                key: 'app-exchanges',
                label: 'All Exchanges',
                url: '/exchanges/',
                parentKey: 'apps-exchanges',
                permissions: ["View Exchanges"]
            },
            {
                key: 'app-exchange-transactions',
                label: 'Exchange Transactions',
                url: '/exchange-transactions/',
                parentKey: 'apps-exchanges',
                permissions: ["View Exchange Transactions"]
            },
        ],
    },
    {
        key: 'apps-transfers',
        label: 'Transfer To Friends',
        isTitle: false,
        icon: 'uil-exchange-alt',
        permissions: ["View Transfers", "Create Transfer"],
        children: [
            {
                key: 'app-new-fr-transfer',
                label: 'New Transfer',
                url: '/transfers/create/',
                parentKey: 'apps-transfers',
                permissions: ["Create Transfer"]
            },
            {
                key: 'app-transfers',
                label: 'All Transfers',
                url: '/transfers/',
                parentKey: 'apps-transfers',
                permissions: ["View Transfers"]
            },
            {
                key: 'app-transfer-transactions',
                label: 'Transfer Transactions',
                url: '/transfer-transactions/',
                parentKey: 'apps-transfers',
                permissions: ["View Transfer Transactions"]
            },
        ],
    },
    {
        key: 'apps-orders',
        label: 'Orders',
        isTitle: false,
        icon: 'uil-money-stack',
        permissions: ["View Orders", "View All Orders", "Create Order"],
        children: [
            {
                key: 'app-create-order',
                label: 'New Order',
                url: '/orders/create',
                parentKey: 'apps-orders',
                permissions: ["Create Order"],
            },
            {
                key: 'app-order-history',
                label: 'Order History',
                url: '/orders/',
                parentKey: 'apps-orders',
                permissions: ["View Orders", "View All Orders"],
            },
            {
                key: 'app-order-bills',
                label: 'Order Bills History',
                url: '/orders/bills',
                parentKey: 'apps-orders',
                permissions: ["View Orders", "View All Orders"],
            },
            {
                key: 'app-order-transactions',
                label: 'Order Transactions',
                url: '/orders/transactions',
                parentKey: 'apps-orders',
                permissions: ["View Orders", "View All Orders"],
            },

        ],
    },

    // { key: 'INTPaymentSec', label: 'International Payments', isTitle: true, permissions: ["*"] },
    {
        key: 'apps-payments',
        label: 'International Payment',
        isTitle: false,
        icon: 'uil-chart-line',
        permissions: ["View Payments"],
        children: [
            {
                key: 'app-payments',
                label: 'All Payments',
                url: '/payments/',
                parentKey: 'apps-payments',
                permissions: ["View Payments"]
            },
            {
                key: 'app-payment-transactions',
                label: 'Payment Transactions',
                url: '/payment-transactions/',
                parentKey: 'apps-payments',
                permissions: ["View Payment Transactions"]
            },
        ],
    },


    {
        key: 'app-tr-histories',
        label: 'Transaction History',
        url: '/transactions/',
        icon: 'uil-history',
        permissions: ["View Transactions"]
    },
    {
        key: 'apps-bank-accounts',
        label: 'Bank Accounts',
        isTitle: false,
        icon: 'uil-atm-card',
        permissions: ["View Bank Cards", "View IBANs"],
        children: [
            {
                key: 'app-bank-cards',
                label: 'All Bank Cards',
                url: '/bank-cards/',
                parentKey: 'apps-bank-accounts',
                permissions: ["View Bank Cards"]
            },
            {
                key: 'app-ibans',
                label: 'All IBANs',
                url: '/ibans/',
                parentKey: 'apps-bank-accounts',
                permissions: ["View IBANs"]
            },
        ],
    },

    {
        key: 'apps-tokens',
        label: 'Developers',
        isTitle: false,
        icon: 'uil-code',
        permissions: ["View Tokens", "Create Token"],
        children: [
            {
                key: 'app-new-token',
                label: 'New Token',
                url: '/tokens/create/',
                parentKey: 'apps-tokens',
                permissions: ["Create Token"]
            },
            {
                key: 'app-tokens',
                label: 'All Tokens',
                url: '/tokens/',
                parentKey: 'apps-tokens',
                permissions: ["View Tokens"]
            },
            {
                key: 'app-new-ip',
                label: 'New IP',
                url: '/trusted-ips/create/',
                parentKey: 'apps-tokens',
                permissions: ["Create Token"]
            },
            {
                key: 'app-ips',
                label: 'Trusted IPs',
                url: '/trusted-ips/',
                parentKey: 'apps-tokens',
                permissions: ["View Tokens"]
            },
            {
                key: 'app-resources',
                label: 'Resources',
                url: '/resources/',
                parentKey: 'apps-tokens',
                permissions: ["View Tokens"]
            },
        ],
    },
    // {
    //     key: 'app-usd-rate',
    //     label: 'USD Rate',
    //     url: '/rates/',
    //     icon: 'uil-dollar-alt',
    //     permissions: ["Modify System Settings"]
    // },
    {
        key: 'app-my-account',
        label: 'My Account',
        url: '/my-account/',
        icon: 'mdi mdi-account-circle',
        permissions: ["*"]
    },
    {
        key: 'app-setting',
        label: 'Settings',
        url: '/settings/',
        icon: 'mdi mdi-account-circle',
        permissions: ["Modify System Settings"]
    },
    {
        key: 'app-cr',
        label: 'Conversion Rates',
        url: '/conversion-rates/',
        icon: 'mdi mdi-account-circle',
        permissions: ["Modify System Settings"]
    },

    // {
    //     key: 'app-gift',
    //     label: 'Gift Cards',
    //     icon: 'mdi mdi-logout',
    //     url: '/gift-cards',
    //     permissions: ["*"]
    // },

    {
        key: 'app-logout',
        label: 'Logout',
        icon: 'mdi mdi-logout',
        url: '/account/logout',
        permissions: ["*"]
    },

    // {
    //     key: 'apps-receive',
    //     label: 'Receive',
    //     isTitle: false,
    //     icon: 'uil-arrow-down',
    //     permissions: ["View Receive Transactions"],
    //     children: [
    //         {
    //             key: 'app-receives',
    //             label: 'All Transactions',
    //             url: '/receive/',
    //             parentKey: 'apps-receives',
    //             permissions: ["View Receive Transactions"]
    //         },
    //         {
    //             key: 'app-new-receive',
    //             label: 'New Request',
    //             url: '/receive/create/',
    //             parentKey: 'apps-receives',
    //             permissions: ["Create Receive Transaction"]
    //         },
    //     ],
    // },
    // {
    //     key: 'apps-exchanges',
    //     label: 'All Transactions',
    //     isTitle: false,
    //     icon: 'uil-money-stack',
    //     permissions: ["View Transactions"],
    //     url: '/transactions/'
    // },



    // {
    //     key: 'apps-calendar',
    //     label: 'Calendar',
    //     isTitle: false,
    //     icon: 'uil-calender',
    //     url: '/apps/calendar',
    // },
    //
    // {
    //     key: 'apps-chat',
    //     label: 'Chat',
    //     isTitle: false,
    //     icon: 'uil-comments-alt',
    //     url: '/apps/chat',
    // },
    //
    // {
    //     key: 'apps-crm',
    //     label: 'CRM',
    //     isTitle: false,
    //     badge: { variant: 'danger', text: 'New' },
    //     icon: 'uil-tachometer-fast',
    //     children: [
    //         {
    //             key: 'crm-products',
    //             label: 'Dashboard',
    //             url: '/apps/crm/dashboard',
    //             parentKey: 'apps-crm',
    //         },
    //         {
    //             key: 'crm-projects',
    //             label: 'Project',
    //             url: '/apps/crm/projects',
    //             parentKey: 'apps-crm',
    //         },
    //         {
    //             key: 'crm-orders',
    //             label: 'Orders List',
    //             url: '/apps/crm/orders',
    //             parentKey: 'apps-crm',
    //         },
    //         {
    //             key: 'crm-clients',
    //             label: 'Clients',
    //             url: '/apps/crm/clients',
    //             parentKey: 'apps-crm',
    //         },
    //         {
    //             key: 'crm-management',
    //             label: 'Management',
    //             url: '/apps/crm/management',
    //             parentKey: 'apps-crm',
    //         },
    //     ],
    // },
    //
    //
    //
    // {
    //     key: 'apps-ecommerce',
    //     label: 'Ecommerce',
    //     isTitle: false,
    //     icon: 'uil-store',
    //     permissions: ["View Dashboard"],
    //     children: [
    //         {
    //             key: 'ecommerce-products',
    //             label: 'Products',
    //             url: '/apps/ecommerce/products',
    //             parentKey: 'apps-ecommerce',
    //         },
    //         {
    //             key: 'ecommerce-details',
    //             label: 'Products Details',
    //             url: '/apps/ecommerce/details',
    //             parentKey: 'apps-ecommerce',
    //         },
    //         {
    //             key: 'ecommerce-orders',
    //             label: 'Orders',
    //             url: '/apps/ecommerce/orders',
    //             parentKey: 'apps-ecommerce',
    //         },
    //         {
    //             key: 'ecommerce-order-details',
    //             label: 'Order Details',
    //             url: '/apps/ecommerce/order/details',
    //             parentKey: 'apps-ecommerce',
    //         },
    //         {
    //             key: 'ecommerce-customers',
    //             label: 'Customers',
    //             url: '/apps/ecommerce/customers',
    //             parentKey: 'apps-ecommerce',
    //         },
    //         {
    //             key: 'ecommerce-shopping-cart',
    //             label: 'Shopping Cart',
    //             url: '/apps/ecommerce/shopping-cart',
    //             parentKey: 'apps-ecommerce',
    //         },
    //         {
    //             key: 'ecommerce-checkout',
    //             label: 'Checkout',
    //             url: '/apps/ecommerce/checkout',
    //             parentKey: 'apps-ecommerce',
    //         },
    //         {
    //             key: 'ecommerce-sellers',
    //             label: 'Sellers',
    //             url: '/apps/ecommerce/sellers',
    //             parentKey: 'apps-ecommerce',
    //         },
    //     ],
    // },
    // {
    //     key: 'apps-email',
    //     label: 'Email',
    //     isTitle: false,
    //     icon: 'uil-envelope',
    //     permissions: ["View Dashboard"],
    //     children: [
    //         {
    //             key: 'email-inbox',
    //             label: 'Inbox',
    //             url: '/apps/email/inbox',
    //             parentKey: 'apps-email',
    //         },
    //         {
    //             key: 'email-read-email',
    //             label: 'Read Email',
    //             url: '/apps/email/details',
    //             parentKey: 'apps-email',
    //         },
    //     ],
    // },
    // {
    //     key: 'apps-projects',
    //     label: 'Projects',
    //     isTitle: false,
    //     icon: 'uil-briefcase',
    //     permissions: ["View Dashboard"],
    //     children: [
    //         {
    //             key: 'project-list',
    //             label: 'List',
    //             url: '/apps/projects/list',
    //             parentKey: 'apps-projects',
    //         },
    //         {
    //             key: 'project-details',
    //             label: 'Details',
    //             url: '/apps/projects/details',
    //             parentKey: 'apps-projects',
    //         },
    //         {
    //             key: 'project-gantt',
    //             label: 'Gantt',
    //             url: '/apps/projects/gantt',
    //             badge: { variant: 'light', text: 'New' },
    //             parentKey: 'apps-projects',
    //         },
    //         {
    //             key: 'project-create-project',
    //             label: 'Create Project',
    //             url: '/apps/projects/new',
    //             parentKey: 'apps-projects',
    //         },
    //     ],
    // },
    // {
    //     key: 'apps-social',
    //     label: 'Social Feed',
    //     isTitle: false,
    //     icon: 'uil-rss',
    //     url: '/apps/social',
    //     permissions: ["View Dashboard"],
    // },
    // {
    //     key: 'apps-tasks',
    //     label: 'Tasks',
    //     isTitle: false,
    //     icon: 'uil-clipboard-alt',
    //     permissions: ["View Dashboard"],
    //     children: [
    //         {
    //             key: 'task-list',
    //             label: 'List',
    //             url: '/apps/tasks/list',
    //             parentKey: 'apps-tasks',
    //         },
    //         {
    //             key: 'task-details',
    //             label: 'Details',
    //             url: '/apps/tasks/details',
    //             parentKey: 'apps-tasks',
    //         },
    //         {
    //             key: 'task-kanban',
    //             label: 'Kanban Board',
    //             url: '/apps/tasks/kanban',
    //             parentKey: 'apps-tasks',
    //         },
    //     ],
    // },
    // {
    //     key: 'apps-file-manager',
    //     label: 'File Manager',
    //     isTitle: false,
    //     icon: 'uil-folder-plus',
    //     url: '/apps/file',
    //     permissions: ["View Dashboard"],
    // },
    //
    // { key: 'custom', label: 'Custom', isTitle: true },
    // {
    //     key: 'pages',
    //     label: 'Pages',
    //     isTitle: false,
    //     icon: 'uil-copy-alt',
    //     permissions: ["View Dashboard"],
    //     children: [
    //         {
    //             key: 'page-profile',
    //             label: 'Profile',
    //             url: '/pages/profile',
    //             parentKey: 'pages',
    //         },
    //         {
    //             key: 'page-profile2',
    //             label: 'Profile 2',
    //             url: '/pages/profile2',
    //             parentKey: 'pages',
    //         },
    //         {
    //             key: 'page-invoice',
    //             label: 'Invoice',
    //             url: '/pages/invoice',
    //             parentKey: 'pages',
    //         },
    //         { key: 'page-faq', label: 'FAQ', url: '/pages/faq', parentKey: 'pages' },
    //         {
    //             key: 'page-pricing',
    //             label: 'Pricing',
    //             url: '/pages/pricing',
    //             parentKey: 'pages',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'page-maintenance',
    //             label: 'Maintenance',
    //             url: '/maintenance',
    //             target: '_blank',
    //             parentKey: 'pages',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'page-error-404',
    //             label: 'Error - 404',
    //             url: '/error-404',
    //             parentKey: 'pages',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'page-error-404-alt',
    //             label: 'Error - 404-alt',
    //             url: '/pages/error-404-alt',
    //             parentKey: 'pages',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'page-error-500',
    //             label: 'Error - 500',
    //             url: '/error-500',
    //             parentKey: 'pages',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'page-starter',
    //             label: 'Starter Page',
    //             url: '/pages/starter',
    //             parentKey: 'pages',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'page-preloader',
    //             label: 'With Preloader',
    //             url: '/pages/preloader',
    //             parentKey: 'pages',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'page-timeline',
    //             label: 'Timeline',
    //             url: '/pages/timeline',
    //             parentKey: 'pages',
    //             permissions: ["View Dashboard"],
    //         },
    //     ],
    // },
    // {
    //     key: 'landing',
    //     label: 'Landing',
    //     isTitle: false,
    //     icon: 'uil-globe',
    //     url: '/landing',
    //     target: '_blank',
    //     badge: { variant: 'secondary', text: 'New' },
    // },
    //
    // { key: 'components', label: 'components', isTitle: true },
    // {
    //     key: 'base-ui',
    //     label: 'Base UI',
    //     isTitle: false,
    //     icon: 'uil-box',
    //     permissions: ["View Dashboard"],
    //     children: [
    //         {
    //             key: 'base-ui-accordions',
    //             label: 'Accordions',
    //             url: '/ui/base-ui/accordions',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-alerts',
    //             label: 'Alerts',
    //             url: '/ui/base-ui/alerts',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-avatars',
    //             label: 'Avatars',
    //             url: '/ui/base-ui/avatars',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-badges',
    //             label: 'Badges',
    //             url: '/ui/base-ui/badges',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-breadcrumb',
    //             label: 'Breadcrumb',
    //             url: '/ui/base-ui/breadcrumb',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-buttons',
    //             label: 'Buttons',
    //             url: '/ui/base-ui/buttons',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-cards',
    //             label: 'Cards',
    //             url: '/ui/base-ui/cards',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-carousel',
    //             label: 'Carousel',
    //             url: '/ui/base-ui/carousel',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-dropdown',
    //             label: 'Dropdowns',
    //             url: '/ui/base-ui/dropdowns',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-embedvideo',
    //             label: 'Embed Video',
    //             url: '/ui/base-ui/embedvideo',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-grid',
    //             label: 'Grid',
    //             url: '/ui/base-ui/grid',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-listgroups',
    //             label: 'List Groups',
    //             url: '/ui/base-ui/listgroups',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-modals',
    //             label: 'Modals',
    //             url: '/ui/base-ui/modals',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-notifications',
    //             label: 'Notifications',
    //             url: '/ui/base-ui/notifications',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-offcanvas',
    //             label: 'Offcanvas',
    //             url: '/ui/base-ui/offcanvas',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-placeholders',
    //             label: 'Placeholders',
    //             url: '/ui/base-ui/placeholders',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-paginations',
    //             label: 'Paginations',
    //             url: '/ui/base-ui/paginations',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-popovers',
    //             label: 'Popovers',
    //             url: '/ui/base-ui/popovers',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-progress',
    //             label: 'Progress',
    //             url: '/ui/base-ui/progress',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-ribbons',
    //             label: 'Ribbons',
    //             url: '/ui/base-ui/ribbons',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-spinners',
    //             label: 'Spinners',
    //             url: '/ui/base-ui/spinners',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-tabs',
    //             label: 'Tabs',
    //             url: '/ui/base-ui/tabs',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-tooltips',
    //             label: 'Tooltips',
    //             url: '/ui/base-ui/tooltips',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'base-ui-typography',
    //             label: 'Typography',
    //             url: '/ui/base-ui/typography',
    //             parentKey: 'base-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //     ],
    // },
    // {
    //     key: 'extended-ui',
    //     label: 'Extended UI',
    //     isTitle: false,
    //     icon: 'uil-package',
    //     permissions: ["View Dashboard"],
    //     children: [
    //         {
    //             key: 'extended-ui-dragdrop',
    //             label: 'Drag and Drop',
    //             url: '/ui/extended/dragdrop',
    //             parentKey: 'extended-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'extended-ui-rangesliders',
    //             label: 'Range Sliders',
    //             url: '/ui/extended/rangesliders',
    //             parentKey: 'extended-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'extended-ui-ratings',
    //             label: 'Ratings',
    //             url: '/ui/extended/ratings',
    //             parentKey: 'extended-ui',
    //             permissions: ["View Dashboard"],
    //         },
    //     ],
    // },
    // {
    //     key: 'widgets',
    //     label: 'Widgets',
    //     isTitle: false,
    //     icon: 'uil-layer-group',
    //     url: '/ui/widgets',
    //     permissions: ["View Dashboard"],
    // },
    // {
    //     key: 'icons',
    //     label: 'Icons',
    //     isTitle: false,
    //     icon: 'uil-streering',
    //     permissions: ["View Dashboard"],
    //     children: [
    //         {
    //             key: 'icon-dripicons',
    //             label: 'Dripicons',
    //             url: '/ui/icons/dripicons',
    //             permissions: ["View Dashboard"],
    //             parentKey: 'icons',
    //         },
    //         {
    //             key: 'icon-mdiicons',
    //             label: 'Material Design',
    //             url: '/ui/icons/mdi',
    //             parentKey: 'icons',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'icon-unicons',
    //             label: 'Unicons',
    //             url: '/ui/icons/unicons',
    //             parentKey: 'icons',
    //             permissions: ["View Dashboard"],
    //         },
    //     ],
    // },
    // {
    //     key: 'forms',
    //     label: 'Forms',
    //     isTitle: false,
    //     icon: 'uil-document-layout-center',
    //     permissions: ["View Dashboard"],
    //     children: [
    //         {
    //             key: 'form-basic',
    //             label: 'Basic Elements',
    //             url: '/ui/forms/basic',
    //             parentKey: 'forms',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'form-advanced',
    //             label: 'Form Advanced',
    //             url: '/ui/forms/advanced',
    //             parentKey: 'forms',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'form-validation',
    //             label: 'Validation',
    //             url: '/ui/forms/validation',
    //             parentKey: 'forms',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'form-wizard',
    //             label: 'Wizard',
    //             url: '/ui/forms/wizard',
    //             parentKey: 'forms',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'form-upload',
    //             label: 'File Upload',
    //             url: '/ui/forms/upload',
    //             parentKey: 'forms',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'form-editors',
    //             label: 'Editors',
    //             url: '/ui/forms/editors',
    //             parentKey: 'forms',
    //             permissions: ["View Dashboard"],
    //         },
    //     ],
    // },
    // {
    //     key: 'charts',
    //     label: 'Charts',
    //     isTitle: false,
    //     icon: 'uil-chart',
    //     permissions: ["View Dashboard"],
    //     children: [
    //         {
    //             key: 'chart-apex',
    //             label: 'Apex Charts',
    //             url: '/ui/charts/apex',
    //             parentKey: 'charts',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'chart-chartjs',
    //             label: 'Chartjs',
    //             url: '/ui/charts/chartjs',
    //             parentKey: 'charts',
    //             permissions: ["View Dashboard"],
    //         },
    //     ],
    // },
    // {
    //     key: 'tables',
    //     label: 'Tables',
    //     isTitle: false,
    //     icon: 'uil-table',
    //     permissions: ["View Dashboard"],
    //     children: [
    //         {
    //             key: 'table-basic',
    //             label: 'Basic Tables',
    //             url: '/ui/tables/basic',
    //             parentKey: 'tables',
    //             permissions: ["View Dashboard"],
    //         },
    //         {
    //             key: 'table-advanced',
    //             label: 'Advanced Tables',
    //             url: '/ui/tables/advanced',
    //             parentKey: 'tables',
    //             permissions: ["View Dashboard"],
    //         },
    //     ],
    // },
    // {
    //     key: 'maps',
    //     label: 'Maps',
    //     isTitle: false,
    //     icon: 'uil-location-point',
    //     children: [
    //         {
    //             key: 'maps-googlemaps',
    //             label: 'Google Maps',
    //             url: '/ui/maps/googlemaps',
    //             parentKey: 'maps',
    //         },
    //         {
    //             key: 'maps-vectormaps',
    //             label: 'Vector Maps',
    //             url: '/ui/maps/vectormaps',
    //             parentKey: 'maps',
    //         },
    //     ],
    // }
];

export { MENU_ITEMS };
