export enum OrderTransactionActionTypes {
    API_RESPONSE_SUCCESS = '@@orderTransaction/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@orderTransaction/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@orderTransaction/API_RESPONSE_VALIDATION_ERRORS',

    GET_TRANSACTIONS = '@@orderTransaction/GET_TRANSACTIONS',
    GET_TRANSACTION = '@@orderTransaction/GET_TRANSACTION',

    OPEN_SEARCH = '@@orderTransaction/OPEN_SEARCH',
    CLOSE_SEARCH = '@@orderTransaction/CLOSE_SEARCH',
    SEARCH_TRANSACTION = '@@orderTransaction/SEARCH_TRANSACTION',
    CLEAR_SEARCH = '@@orderTransaction/CLEAR_SEARCH',

    RESET = '@@orderTransaction/RESET',

    EXPORT = '@@orderTransaction/EXPORT',
    RESET_SEARCH = '@@orderTransaction/RESET_SEARCH',
}

export enum OrderTransactionErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.',
}



