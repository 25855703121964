import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql } from 'helpers';
import {OrderTransactionActionTypes, OrderTransactionErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse,
} from "../../helpers/functions";
import {orderTransactionActions} from "./actions";

type OrderTransactionData = {
    payload: {

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;
        };

        data: any;

        id: string;
    };
    type: string;
};

function* getOrderTransactions({ payload: { queryParams } }: OrderTransactionData): SagaIterator {

    try {
        const param = {
            query:`query OrderTransactions($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String) {
                orderTransactions(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId) {
                    data {
                        wallet {
                            uuid
                            name
                            holder {
                                id
                                email
                                username
                                display_name
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        type
                        amountFloat
                        balanceFloat
                        confirmed
                        meta {
                            description
                        }
                        name
                        transaction_id
                        thread_id
                        status
                        created_at
                        updated_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },

            operationName: "OrderTransactions"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderTransactionErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const orderTransactions = pluckResponse(rTransactions, "orderTransactions");

        const result = {
            transactions: orderTransactions,
            queryParams
        }

        yield put(orderTransactionActions.apiResponseSuccess(OrderTransactionActionTypes.GET_TRANSACTIONS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderTransactionActions.apiResponseError(OrderTransactionActionTypes.GET_TRANSACTIONS, error));
        } else {
            yield put(orderTransactionActions.apiResponseValidationErrors(OrderTransactionActionTypes.GET_TRANSACTIONS, error));
        }
    }
}

function* searchOrderTransaction({ payload: { queryParams } }: OrderTransactionData): SagaIterator {
    try {
        const param = {
            query:`query OrderTransactions(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $threadId: String,
                $accountId: String,
                $transactionNames: [String],
                $transactionType: String
            ) {
                orderTransactions(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    threadId: $threadId,
                    accountId: $accountId,
                    transactionNames: $transactionNames,
                    transactionType: $transactionType
                ) {
                    data {
                        wallet {
                            uuid
                            name
                            holder {
                                id
                                email
                                username
                                display_name
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        type
                        amountFloat
                        balanceFloat
                        confirmed
                        meta {
                            description
                        }
                        name
                        transaction_id
                        thread_id
                        status
                        created_at
                        updated_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },

            operationName: "OrderTransactions"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderTransactionErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const orderTransactions = pluckResponse(rTransactions, "orderTransactions");

        const result = {
            transactions: orderTransactions,
            queryParams
        }

        yield put(orderTransactionActions.apiResponseSuccess(OrderTransactionActionTypes.SEARCH_TRANSACTION, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderTransactionActions.apiResponseError(OrderTransactionActionTypes.SEARCH_TRANSACTION, error));
        } else {
            yield put(orderTransactionActions.apiResponseValidationErrors(OrderTransactionActionTypes.SEARCH_TRANSACTION, error));
        }
    }
}

function* exportOrderTransactions({ payload: { queryParams } }: OrderTransactionData): SagaIterator {
    try {
        const param = {
            query:`query ExportOrderTransactions(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $threadId: String,
                $accountId: String,
                $transactionNames: [String],
                $transactionType: String
            ) {
                exportOrderTransactions(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    threadId: $threadId,
                    accountId: $accountId,
                    transactionNames: $transactionNames,
                    transactionType: $transactionType
                )
            }`,

            variables: {
                ...queryParams
            },

            operationName: "ExportOrderTransactions"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderTransactionErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const orderTransactions = pluckResponse(rTransactions, "exportOrderTransactions");

        yield put(orderTransactionActions.apiResponseSuccess(OrderTransactionActionTypes.EXPORT, orderTransactions));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderTransactionActions.apiResponseError(OrderTransactionActionTypes.EXPORT, error));
        } else {
            yield put(orderTransactionActions.apiResponseValidationErrors(OrderTransactionActionTypes.EXPORT, error));
        }
    }
}

function* getOrderTransaction({ payload: { id } }: OrderTransactionData): SagaIterator {

    try {
        const param = {
            query:`query OrderTransaction($id: String!) {
                orderTransaction(id: $id) {
                    wallet {
                        uuid
                        name
                        holder {
                            id
                            email
                            username
                            display_name
                        }
                        meta {
                            sign
                            currency
                        }
                    }
                    uuid
                    type
                    amount
                    confirmed
                    meta {
                        description
                    }
                    method
                    bank_name
                    card_number
                    fee
                    discount
                    name
                    transaction_id
                    thread_id
                    status
                    created_at
                    updated_at
                }
            }`,

            variables: {
                id: id
            },

            operationName: "OrderTransaction"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderTransactionErrors.RESPONSE_200);

        const rT = response.data;

        checkServerError(rT);

        const orderTransaction = pluckResponse(rT, "orderTransaction");

        yield put(orderTransactionActions.apiResponseSuccess(OrderTransactionActionTypes.GET_TRANSACTION, orderTransaction));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderTransactionActions.apiResponseError(OrderTransactionActionTypes.GET_TRANSACTION, error));
        } else {
            yield put(orderTransactionActions.apiResponseValidationErrors(OrderTransactionActionTypes.GET_TRANSACTION, error));
        }
    }
}

export function* watchGetOrderTransactions() {
    yield takeEvery(OrderTransactionActionTypes.GET_TRANSACTIONS, getOrderTransactions);
}

export function* watchSearchOrderTransaction() {
    yield takeEvery(OrderTransactionActionTypes.SEARCH_TRANSACTION, searchOrderTransaction);
}

export function* watchGetOrderTransaction() {
    yield takeEvery(OrderTransactionActionTypes.GET_TRANSACTION, getOrderTransaction);
}

export function* watchExportOrderTransactions() {
    yield takeEvery(OrderTransactionActionTypes.EXPORT, exportOrderTransactions);
}


function* orderTransactionsSaga() {
    yield all([
        fork(watchGetOrderTransactions),
        fork(watchSearchOrderTransaction),
        fork(watchExportOrderTransactions),
        fork(watchGetOrderTransaction)
    ]);
}

export default orderTransactionsSaga;
