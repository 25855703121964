import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql } from 'helpers';
import {OrderBillActionTypes, OrderBillErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse,
} from "../../helpers/functions";
import {orderBillActions} from "./actions";

type OrderBillData = {
    payload: {

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;
        };

        data: any;

        id: string;
    };
    type: string;
};

function* getOrderBills({ payload: { queryParams } }: OrderBillData): SagaIterator {

    try {
        const param = {
            query:`query OrderBills(
                $page: Int!,
                $limit: Int,
                $orderBy: String!,
                $sort: String!,
                $accountId: String,
            ) {
                orderBills(
                    page: $page,
                    limit: $limit,
                    orderBy: $orderBy,
                    sort: $sort,
                    accountId: $accountId
                ) {
                    data {
                        hashedId
                        created_at
                        amount
                        currency
                        user {
                            id
                            username
                        }
                        model {
                            hashedId
                        }
                        transaction {
                            transaction_id
                            thread_id
                        }
                        amount
                        expire_date
                        status
                        description
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },

            operationName: "OrderBills"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderBillErrors.RESPONSE_200);

        const rBills = response.data;

        checkServerError(rBills);

        const orderBills = pluckResponse(rBills, "orderBills");

        const result = {
            bills: orderBills,
            queryParams
        }

        yield put(orderBillActions.apiResponseSuccess(OrderBillActionTypes.GET_BILLS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderBillActions.apiResponseError(OrderBillActionTypes.GET_BILLS, error));
        } else {
            yield put(orderBillActions.apiResponseValidationErrors(OrderBillActionTypes.GET_BILLS, error));
        }
    }
}

function* searchOrderBill({ payload: { queryParams } }: OrderBillData): SagaIterator {
    try {
        const param = {
            query:`query OrderBills(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!,
                $billId: String,
                $orderId: String,
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $accountId: String,
            ) {
                orderBills(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort,
                    billId: $billId,
                    orderId: $orderId,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    accountId: $accountId,
                ) {
                    data {
                        hashedId
                        created_at
                        amount
                        currency
                        user {
                            id
                            username
                        }
                        model {
                            hashedId
                        }
                        transaction {
                            transaction_id
                            thread_id
                        }
                        amount
                        expire_date
                        status
                        description
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            },

            operationName: "OrderBills"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderBillErrors.RESPONSE_200);

        const rBills = response.data;

        checkServerError(rBills);

        const orderBills = pluckResponse(rBills, "orderBills");

        const result = {
            bills: orderBills,
            queryParams
        }

        yield put(orderBillActions.apiResponseSuccess(OrderBillActionTypes.SEARCH_BILL, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderBillActions.apiResponseError(OrderBillActionTypes.SEARCH_BILL, error));
        } else {
            yield put(orderBillActions.apiResponseValidationErrors(OrderBillActionTypes.SEARCH_BILL, error));
        }
    }
}

function* exportOrderBills({ payload: { queryParams } }: OrderBillData): SagaIterator {
    try {
        const param = {
            query:`query ExportOrderBills(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!,
                $billId: String,
                $orderId: String,
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $accountId: String,
            ) {
                exportOrderBills(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort,
                    billId: $billId,
                    orderId: $orderId,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    accountId: $accountId,
                )
            }`,

            variables: {
                ...queryParams
            },

            operationName: "ExportOrderBills"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderBillErrors.RESPONSE_200);

        const rBills = response.data;

        checkServerError(rBills);

        const orderBills = pluckResponse(rBills, "exportOrderBills");

        yield put(orderBillActions.apiResponseSuccess(OrderBillActionTypes.EXPORT, orderBills));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderBillActions.apiResponseError(OrderBillActionTypes.EXPORT, error));
        } else {
            yield put(orderBillActions.apiResponseValidationErrors(OrderBillActionTypes.EXPORT, error));
        }
    }
}

function* getOrderBill({ payload: { id } }: OrderBillData): SagaIterator {

    try {
        const param = {
            query:`query OrderBill($id: String!) {
                orderBill(id: $id) {
                    hashedId
                    created_at
                    amount
                    currency
                    user {
                        id
                        username
                    }
                    model {
                        hashedId
                    }
                    transaction {
                        transaction_id
                        thread_id
                    }
                    amount
                    expire_date
                    status
                    description
                }
            }`,

            variables: {
                id: id
            },

            operationName: "OrderBill"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, OrderBillErrors.RESPONSE_200);

        const rT = response.data;

        checkServerError(rT);

        const orderBill = pluckResponse(rT, "orderBill");

        yield put(orderBillActions.apiResponseSuccess(OrderBillActionTypes.GET_BILL, orderBill));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(orderBillActions.apiResponseError(OrderBillActionTypes.GET_BILL, error));
        } else {
            yield put(orderBillActions.apiResponseValidationErrors(OrderBillActionTypes.GET_BILL, error));
        }
    }
}

export function* watchGetOrderBills() {
    yield takeEvery(OrderBillActionTypes.GET_BILLS, getOrderBills);
}

export function* watchSearchOrderBill() {
    yield takeEvery(OrderBillActionTypes.SEARCH_BILL, searchOrderBill);
}

export function* watchGetOrderBill() {
    yield takeEvery(OrderBillActionTypes.GET_BILL, getOrderBill);
}

export function* watchExportOrderBills() {
    yield takeEvery(OrderBillActionTypes.EXPORT, exportOrderBills);
}


function* orderBillsSaga() {
    yield all([
        fork(watchGetOrderBills),
        fork(watchSearchOrderBill),
        fork(watchExportOrderBills),
        fork(watchGetOrderBill)
    ]);
}

export default orderBillsSaga;
