export enum OrderActionTypes {
    API_RESPONSE_SUCCESS = '@@order/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@order/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@order/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@order/RESET',

    ORDERS = '@@order/ORDERS',
    ORDER = '@@order/ORDER',
    CREATE = '@@order/CREATE',
    UPDATE = '@@order/UPDATE',
    DELETE = '@@order/DELETE',
    SEARCH = '@@order/SEARCH',

    RATES = '@@order/RATES',

    PREVIEW_PAYMENT_DETAIL = '@@order/PREVIEW_PAYMENT_DETAIL',
    RESET_PREVIEW_DETAIL = '@@order/RESET_PREVIEW_DETAIL',

    PAYMENT_STEP_1 = '@@order/PAYMENT_STEP_1',
    PAYMENT_STEP_2 = '@@order/PAYMENT_STEP_2',

    CREATE_BILL = '@@order/CREATE_BILL',
    UPDATE_BILL = '@@order/UPDATE_BILL',
    ORDER_BILLS = '@@order/ORDER_BILLS',
    ORDER_BILL = '@@order/ORDER_BILL',

    CREATE_NOTE = '@@order/CREATE_NOTE',

    GET_TRANSACTIONS = '@@order/GET_TRANSACTIONS',
    GET_TRANSACTION = '@@order/GET_TRANSACTION',

    OPEN_SEARCH = '@@order/OPEN_SEARCH',
    CLOSE_SEARCH = '@@order/CLOSE_SEARCH',
    SEARCH_ORDER = '@@order/SEARCH_ORDER',
    CLEAR_SEARCH = '@@order/CLEAR_SEARCH',

    EXPORT = '@@order/EXPORT',
    RESET_SEARCH = '@@order/RESET_SEARCH',
}

export enum OrderErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.'
}
