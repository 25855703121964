import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {PremiumSubscriptionActionTypes, PremiumSubscriptionErrors} from "./constants";
import {premiumSubscriptionActions} from "./actions";
import {graphql} from "../../helpers";
import {SagaIterator} from "@redux-saga/core";
import {checkResponseError, checkServerError, pluckResponse} from "../../helpers/functions";

type PremiumSubscriptionData = {
    payload: {
        id: string;

        limit: string;
        page: string;

        queryParams: {
            limit: string;
            page: string;
            premiumSubscriptionBy: string;
            sort: string;
        };

        data: any;
        trackId: string;
        hashedId: string;
    };
    type: string;
};

function* order({ payload: { queryParams } }: PremiumSubscriptionData): SagaIterator {
    try {
        const param = {
            query:`query OrderPremiumSubscription($hashedId: String!) {
                orderPremiumSubscription(hashedId: $hashedId) {
                    hashedId
                    user {
                        id
                        username
                    }
                    created_at
                    updated_at
                    amount
                    currency
                    url
                    data {
                        username
                        password
                        main_item
                        sub_item
                        plan
                    }
                    rate
                    rate_currency
                    fee
                    fee_currency
                    discount
                    discount_currency
                    name
                    final_amount
                    final_amount_currency
                    description
                    status
                    bills {
                        hashedId
                        amount
                        currency
                        status
                        created_at
                    }
                    notes {
                        note
                        created_at
                    }
                    transactions {
                        transaction {
                            transaction_id
                            thread_id
                            type
                            status
                            name
                            amountFloat
                            wallet {
                                name
                            }
                            created_at
                        }
                    }
                }
            }`,

            variables: {
                ...queryParams
            },
            operationName: "OrderPremiumSubscription"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PremiumSubscriptionErrors.RESPONSE_200);

        const rPremiumSubscription = response.data;

        checkServerError(rPremiumSubscription);

        const order = pluckResponse(rPremiumSubscription, "orderPremiumSubscription");

        yield put(premiumSubscriptionActions.apiResponseSuccess(PremiumSubscriptionActionTypes.ORDER, order));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(premiumSubscriptionActions.apiResponseError(PremiumSubscriptionActionTypes.ORDER, error));
        } else {
            yield put(premiumSubscriptionActions.apiResponseValidationErrors(PremiumSubscriptionActionTypes.ORDER, error));
        }
    }
}

function* create({ payload: {data} }: PremiumSubscriptionData): SagaIterator {

    try {
        const param = {

            query:`mutation CreatePremiumSubscription(
                $planUuid: String!,
                $username: String,
                $password: String,
                $acceptConditions: String!,
                $descriptions: String,
            ) {
                createPremiumSubscription(
                    planUuid: $planUuid,
                    username: $username,
                    password: $password,
                    acceptConditions: $acceptConditions,
                    descriptions: $descriptions,
                )
            }`,

            variables: { ...data },
            operationName: "CreatePremiumSubscription"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PremiumSubscriptionErrors.RESPONSE_200);

        const rPremiumSubscription = response.data;

        checkServerError(rPremiumSubscription);

        const createPremiumSubscription = pluckResponse(rPremiumSubscription, "createPremiumSubscription");

        yield put(premiumSubscriptionActions.apiResponseSuccess(PremiumSubscriptionActionTypes.CREATE, createPremiumSubscription));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(premiumSubscriptionActions.apiResponseError(PremiumSubscriptionActionTypes.CREATE, error));
        } else {
            yield put(premiumSubscriptionActions.apiResponseValidationErrors(PremiumSubscriptionActionTypes.CREATE, error));
        }
    }
}

function* update({ payload: {data} }: PremiumSubscriptionData): SagaIterator {
    try {
        const param = {

            query:`mutation UpdatePremiumSubscription(
                $hashedId: String!,
                $planUuid: String!,
                $username: String,
                $password: String,
                $descriptions: String,
                $status: String!,
                $note: String,
                $updateRate: Boolean,
            ) {
                updatePremiumSubscription(
                    hashedId: $hashedId,
                    planUuid: $planUuid,
                    username: $username,
                    password: $password,
                    descriptions: $descriptions,
                    status: $status,
                    note: $note,
                    updateRate: $updateRate,
                )
            }`,

            variables: { ...data },
            operationName: "UpdatePremiumSubscription"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PremiumSubscriptionErrors.RESPONSE_200);

        const rPremiumSubscription = response.data;

        checkServerError(rPremiumSubscription);

        pluckResponse(rPremiumSubscription, "updatePremiumSubscription");

        yield put(premiumSubscriptionActions.apiResponseSuccess(PremiumSubscriptionActionTypes.UPDATE, true));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(premiumSubscriptionActions.apiResponseError(PremiumSubscriptionActionTypes.UPDATE, error));
        } else {
            yield put(premiumSubscriptionActions.apiResponseValidationErrors(PremiumSubscriptionActionTypes.UPDATE, error));
        }
    }
}

function* cancel({ payload: {hashedId} }: PremiumSubscriptionData): SagaIterator {
    try {
        const param = {

            query:`mutation CancelPremiumSubscription(
                $hashedId: String!
            ) {
                cancelPremiumSubscription(
                    hashedId: $hashedId
                )
            }`,

            variables: {
                hashedId
            },
            operationName: "CancelPremiumSubscription"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PremiumSubscriptionErrors.RESPONSE_200);

        const rPremiumSubscription = response.data;

        checkServerError(rPremiumSubscription);

        pluckResponse(rPremiumSubscription, "cancelPremiumSubscription");

        yield put(premiumSubscriptionActions.apiResponseSuccess(PremiumSubscriptionActionTypes.CANCEL, hashedId));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(premiumSubscriptionActions.apiResponseError(PremiumSubscriptionActionTypes.CANCEL, error));
        } else {
            yield put(premiumSubscriptionActions.apiResponseValidationErrors(PremiumSubscriptionActionTypes.CANCEL, error));
        }
    }
}

function* review({ payload: { data } }: PremiumSubscriptionData): SagaIterator {
    try {
        const param = {
            query:`query PremiumSubscriptionReview($planUuid: String!) {
                premiumSubscriptionReview(planUuid: $planUuid) {
                    amount
                    amount_currency
                    rate
                    rate_currency
                    fee
                    fee_currency
                    final_amount
                    final_amount_currency
                }
            }`,

            variables: { ...data },
            operationName: "PremiumSubscriptionReview"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PremiumSubscriptionErrors.RESPONSE_200);

        const rOPR = response.data;

        checkServerError(rOPR);

        const premiumSubscriptionReview = pluckResponse(rOPR, "premiumSubscriptionReview");

        yield put(premiumSubscriptionActions.apiResponseSuccess(PremiumSubscriptionActionTypes.REVIEW, premiumSubscriptionReview));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(premiumSubscriptionActions.apiResponseError(PremiumSubscriptionActionTypes.REVIEW, error));
        } else {
            yield put(premiumSubscriptionActions.apiResponseValidationErrors(PremiumSubscriptionActionTypes.REVIEW, error));
        }
    }
}


function* updatePSSetting({ payload: { data, trackId } }: PremiumSubscriptionData): SagaIterator {
    try {

        // متغیرهایی که به Mutation ارسال می‌کنیم:
        const premiumAccounts = data.premiumAccounts || [];
        const premium_subscription_status = data.premium_subscription_status;

        const param = {
            query: `mutation UpdatePremiumSubscriptionSetting(
                $premiumAccounts: [PremiumSubscriptionInput!]!
                $premium_subscription_status: String!
            ) {
                updatePremiumSubscriptionSetting(
                    premiumAccounts: $premiumAccounts
                    premium_subscription_status: $premium_subscription_status
                )
            }`,
            variables: {
                premiumAccounts,
                premium_subscription_status,
            },
            operationName: "UpdatePremiumSubscriptionSetting"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PremiumSubscriptionErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        pluckResponse(responseCR, "updatePremiumSubscriptionSetting");

        yield put(premiumSubscriptionActions.apiResponseSuccess(PremiumSubscriptionActionTypes.UPDATE_SETTING, {
            result: true,
            trackId: trackId
        }));

    } catch (error: any) {
        if (typeof error === "string") {
            yield put(premiumSubscriptionActions.apiResponseError(PremiumSubscriptionActionTypes.UPDATE_SETTING, error, trackId));
        } else {
            yield put(premiumSubscriptionActions.apiResponseValidationErrors(PremiumSubscriptionActionTypes.UPDATE_SETTING, error, trackId));
        }
    }
}


function* getPSSetting({ payload: { data } }: PremiumSubscriptionData): SagaIterator {

    try {
        const param = {
            query:`query PremiumSubscriptionSetting($options: [String!]) {
                premiumSubscriptionSetting(options: $options) {
                    premium_subscription_status
                    premium_subscription_option_items {
                        label
                        subItems {
                            uuid
                            label
                            options {
                                uuid
                                label
                                price
                                currency
                                fee
                            }
                        }
                    }
                }
            }`,

            variables: {
                options: data
            },

            operationName: "PremiumSubscriptionSetting"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, PremiumSubscriptionErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        const premiumSubscriptionSetting = pluckResponse(responseCR, "premiumSubscriptionSetting");

        yield put(premiumSubscriptionActions.apiResponseSuccess(PremiumSubscriptionActionTypes.GET_SETTING, premiumSubscriptionSetting));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(premiumSubscriptionActions.apiResponseError(PremiumSubscriptionActionTypes.GET_SETTING, error));
        } else {
            yield put(premiumSubscriptionActions.apiResponseValidationErrors(PremiumSubscriptionActionTypes.GET_SETTING, error));
        }
    }
}

export function* watchOrder() {
    yield takeEvery(PremiumSubscriptionActionTypes.ORDER, order);
}

export function* watchInsert() {
    yield takeEvery(PremiumSubscriptionActionTypes.CREATE, create);
}

export function* watchUpdate() {
    yield takeEvery(PremiumSubscriptionActionTypes.UPDATE, update);
}

export function* watchCancel() {
    yield takeEvery(PremiumSubscriptionActionTypes.CANCEL, cancel);
}

export function* watchReview() {
    yield takeEvery(PremiumSubscriptionActionTypes.REVIEW, review);
}

export function* watchUpdatePSSetting() {
    yield takeEvery(PremiumSubscriptionActionTypes.UPDATE_SETTING, updatePSSetting);
}

export function* watchGetPSSetting() {
    yield takeEvery(PremiumSubscriptionActionTypes.GET_SETTING, getPSSetting);
}

function* premiumSubscriptionSaga() {
    yield all([
        fork(watchOrder),
        fork(watchInsert),
        fork(watchUpdate),
        fork(watchCancel),
        fork(watchReview),
        fork(watchUpdatePSSetting),
        fork(watchGetPSSetting),
    ]);
}

export default premiumSubscriptionSaga;
