export enum EduExpenseActionTypes {
    API_RESPONSE_SUCCESS = '@@eduExpense/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@eduExpense/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@eduExpense/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@eduExpense/RESET',

    ORDERS = '@@eduExpense/ORDERS',
    ORDER = '@@eduExpense/ORDER',
    CREATE = '@@eduExpense/CREATE',
    UPDATE = '@@eduExpense/UPDATE',
    SEARCH = '@@eduExpense/SEARCH',

    REVIEW = '@@eduExpense/REVIEW',

    CANCEL = '@@eduExpense/CANCEL',
    UPDATE_SETTING = '@@eduExpense/UPDATE_SETTING',
    GET_SETTING = '@@eduExpense/GET_SETTING',
}

export enum EduExpenseErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.'
}



