import {PremiumSubscriptionActionTypes} from './constants';

export type PremiumSubscriptionActionType = {
    type:
        | PremiumSubscriptionActionTypes.API_RESPONSE_SUCCESS
        | PremiumSubscriptionActionTypes.API_RESPONSE_ERROR
        | PremiumSubscriptionActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | PremiumSubscriptionActionTypes.RESET
        | PremiumSubscriptionActionTypes.ORDER
        | PremiumSubscriptionActionTypes.CREATE
        | PremiumSubscriptionActionTypes.UPDATE
        | PremiumSubscriptionActionTypes.SEARCH
        | PremiumSubscriptionActionTypes.REVIEW
        | PremiumSubscriptionActionTypes.CANCEL
        | PremiumSubscriptionActionTypes.UPDATE_SETTING
        | PremiumSubscriptionActionTypes.GET_SETTING;

    payload: {} | string;
};

type PremiumSubscription = {
    id: number;
    name: string;
};

class PremiumSubscriptionActions {
    apiResponseSuccess = (actionType: string, data: PremiumSubscription | {}): PremiumSubscriptionActionType => ({
        type: PremiumSubscriptionActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string, trackId: string = ""): PremiumSubscriptionActionType => ({
        type: PremiumSubscriptionActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error, trackId },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: [], trackId: string = ""): PremiumSubscriptionActionType => ({
        type: PremiumSubscriptionActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors, trackId },
    });

    reset = (): PremiumSubscriptionActionType => ({
        type: PremiumSubscriptionActionTypes.RESET,
        payload: {},
    });

    getPremiumSubscription = (queryParams: any): PremiumSubscriptionActionType => ({
        type: PremiumSubscriptionActionTypes.ORDER,
        payload: { queryParams },
    });

    create = (data: any): PremiumSubscriptionActionType => ({
        type: PremiumSubscriptionActionTypes.CREATE,
        payload: { data },
    });

    update = (data: any): PremiumSubscriptionActionType => ({
        type: PremiumSubscriptionActionTypes.UPDATE,
        payload: { data },
    });

    search = (queryParams: any): PremiumSubscriptionActionType => ({
        type: PremiumSubscriptionActionTypes.SEARCH,
        payload: { queryParams },
    });

    review = (data: any): PremiumSubscriptionActionType => ({
        type: PremiumSubscriptionActionTypes.REVIEW,
        payload: { data },
    });

    cancel = (hashedId: any): PremiumSubscriptionActionType => ({
        type: PremiumSubscriptionActionTypes.CANCEL,
        payload: { hashedId },
    });

    updateSetting = (data: any, trackId: string): PremiumSubscriptionActionType => ({
        type: PremiumSubscriptionActionTypes.UPDATE_SETTING,
        payload: { data, trackId },
    });

    getSetting = (data: any): PremiumSubscriptionActionType => ({
        type: PremiumSubscriptionActionTypes.GET_SETTING,
        payload: { data },
    });
}

const premiumSubscriptionActions = new PremiumSubscriptionActions();
export { premiumSubscriptionActions };
