import {OrderTransactionActionTypes} from './constants';

export type OrderTransactionActionType = {
    type:
        | OrderTransactionActionTypes.API_RESPONSE_SUCCESS
        | OrderTransactionActionTypes.API_RESPONSE_ERROR
        | OrderTransactionActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | OrderTransactionActionTypes.GET_TRANSACTIONS

        | OrderTransactionActionTypes.GET_TRANSACTION

        | OrderTransactionActionTypes.OPEN_SEARCH
        | OrderTransactionActionTypes.CLOSE_SEARCH
        | OrderTransactionActionTypes.SEARCH_TRANSACTION
        | OrderTransactionActionTypes.CLEAR_SEARCH

        | OrderTransactionActionTypes.RESET

        | OrderTransactionActionTypes.EXPORT
        | OrderTransactionActionTypes.RESET_SEARCH;

    payload: {} | string;
};

type OrderTransaction = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    transactionNames?: string
    accountId?: string
}

class OrderTransactionActions {

    apiResponseSuccess = (actionType: string, data: OrderTransaction | {}): OrderTransactionActionType => ({
        type: OrderTransactionActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): OrderTransactionActionType => ({
        type: OrderTransactionActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): OrderTransactionActionType => ({
        type: OrderTransactionActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): OrderTransactionActionType => ({
        type: OrderTransactionActionTypes.RESET,
        payload: {},
    });

    getTransactions = (queryParams: QueryParams): OrderTransactionActionType => ({
        type: OrderTransactionActionTypes.GET_TRANSACTIONS,
        payload: { queryParams },
    });

    getTransaction = (id: string): OrderTransactionActionType => ({
        type: OrderTransactionActionTypes.GET_TRANSACTION,
        payload: { id },
    });

    openSearch = (): OrderTransactionActionType => ({
        type: OrderTransactionActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): OrderTransactionActionType => ({
        type: OrderTransactionActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): OrderTransactionActionType => ({
        type: OrderTransactionActionTypes.SEARCH_TRANSACTION,
        payload: { queryParams }
    });

    clearSearch = (): OrderTransactionActionType => ({
        type: OrderTransactionActionTypes.CLEAR_SEARCH,
        payload: {}
    });

    export = (queryParams: QueryParams): OrderTransactionActionType => ({
        type: OrderTransactionActionTypes.EXPORT,
        payload: { queryParams }
    });

    resetSearch = (): OrderTransactionActionType => ({
        type: OrderTransactionActionTypes.RESET_SEARCH,
        payload: {}
    });
}

const orderTransactionActions = new OrderTransactionActions();
export { orderTransactionActions };
