import {OrderBillActionTypes} from './constants';

export type OrderBillActionType = {
    type:
        | OrderBillActionTypes.API_RESPONSE_SUCCESS
        | OrderBillActionTypes.API_RESPONSE_ERROR
        | OrderBillActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | OrderBillActionTypes.GET_BILLS

        | OrderBillActionTypes.GET_BILL

        | OrderBillActionTypes.OPEN_SEARCH
        | OrderBillActionTypes.CLOSE_SEARCH
        | OrderBillActionTypes.SEARCH_BILL
        | OrderBillActionTypes.CLEAR_SEARCH

        | OrderBillActionTypes.RESET

        | OrderBillActionTypes.EXPORT
        | OrderBillActionTypes.RESET_SEARCH;

    payload: {} | string;
};

type OrderBill = {
    id: number;
    name: string;
    guard_name: string;
};

type QueryParams = {
    page: number;
    limit: number;
    orderBy: string,
    sort: string,
    accountId?: string
}

class OrderBillActions {

    apiResponseSuccess = (actionType: string, data: OrderBill | {}): OrderBillActionType => ({
        type: OrderBillActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): OrderBillActionType => ({
        type: OrderBillActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: []): OrderBillActionType => ({
        type: OrderBillActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    reset = (): OrderBillActionType => ({
        type: OrderBillActionTypes.RESET,
        payload: {},
    });

    getBills = (queryParams: QueryParams): OrderBillActionType => ({
        type: OrderBillActionTypes.GET_BILLS,
        payload: { queryParams },
    });

    getBill = (id: string): OrderBillActionType => ({
        type: OrderBillActionTypes.GET_BILL,
        payload: { id },
    });

    openSearch = (): OrderBillActionType => ({
        type: OrderBillActionTypes.OPEN_SEARCH,
        payload: {}
    });

    closeSearch = (): OrderBillActionType => ({
        type: OrderBillActionTypes.CLOSE_SEARCH,
        payload: {}
    });

    search = (queryParams: QueryParams): OrderBillActionType => ({
        type: OrderBillActionTypes.SEARCH_BILL,
        payload: { queryParams }
    });

    clearSearch = (): OrderBillActionType => ({
        type: OrderBillActionTypes.CLEAR_SEARCH,
        payload: {}
    });

    export = (queryParams: QueryParams): OrderBillActionType => ({
        type: OrderBillActionTypes.EXPORT,
        payload: { queryParams }
    });

    resetSearch = (): OrderBillActionType => ({
        type: OrderBillActionTypes.RESET_SEARCH,
        payload: {}
    });
}

const orderBillActions = new OrderBillActions();
export { orderBillActions };
