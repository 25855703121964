export enum PremiumSubscriptionActionTypes {
    API_RESPONSE_SUCCESS = '@@premiumSubscription/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@premiumSubscription/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@premiumSubscription/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@premiumSubscription/RESET',

    ORDER = '@@premiumSubscription/ORDER',
    CREATE = '@@premiumSubscription/CREATE',
    UPDATE = '@@premiumSubscription/UPDATE',
    SEARCH = '@@premiumSubscription/SEARCH',

    REVIEW = '@@premiumSubscription/REVIEW',

    CANCEL = '@@premiumSubscription/CANCEL',
    UPDATE_SETTING = '@@premiumSubscription/UPDATE_SETTING',
    GET_SETTING = '@@premiumSubscription/GET_SETTING',
}

export enum PremiumSubscriptionErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.'
}



