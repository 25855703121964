export enum IntlMoneyTransferActionTypes {
    API_RESPONSE_SUCCESS = '@@intlMoneyTransfer/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@intlMoneyTransfer/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@intlMoneyTransfer/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@intlMoneyTransfer/RESET',

    ORDERS = '@@intlMoneyTransfer/ORDERS',
    ORDER = '@@intlMoneyTransfer/ORDER',
    CREATE = '@@intlMoneyTransfer/CREATE',
    UPDATE = '@@intlMoneyTransfer/UPDATE',
    SEARCH = '@@intlMoneyTransfer/SEARCH',

    REVIEW = '@@intlMoneyTransfer/REVIEW',

    CANCEL = '@@intlMoneyTransfer/CANCEL',
    UPDATE_SETTING = '@@intlMoneyTransfer/UPDATE_SETTING',
    GET_SETTING = '@@intlMoneyTransfer/GET_SETTING',
}

export enum IntlMoneyTransferErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.'
}



