import { IntlPaymentActionTypes } from './constants';

const INIT_STATE = {
    error: {},
    validationErrors: {},
    loading: {},
    order: {},
    inserted: "",
    updated: false,
    review: {},
    canceled: false,
    trackIds: [],
    settingUpdated: false,
    setting: {},
};

type IntlPaymentActionType = {
    type:
        | IntlPaymentActionTypes.API_RESPONSE_SUCCESS
        | IntlPaymentActionTypes.API_RESPONSE_ERROR
        | IntlPaymentActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | IntlPaymentActionTypes.ORDERS
        | IntlPaymentActionTypes.ORDER
        | IntlPaymentActionTypes.CREATE
        | IntlPaymentActionTypes.UPDATE
        | IntlPaymentActionTypes.RESET
        | IntlPaymentActionTypes.REVIEW
        | IntlPaymentActionTypes.CANCEL
        | IntlPaymentActionTypes.UPDATE_SETTING
        | IntlPaymentActionTypes.GET_SETTING;

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
        validationErrors?: [];

        key: string;
        value?: string;

        page?: string;
        trackId?: string;
    };
};

type State = {
    loading: {},
    error: {},
    order: any,
    trackIds: string[]
};

const OrderIntlPayment = (state: State = INIT_STATE, action: IntlPaymentActionType) => {
    switch (action.type) {

        case IntlPaymentActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case IntlPaymentActionTypes.ORDER: {
                    return {
                        ...state,
                        order: action.payload.data,
                        loading: {
                            order: false
                        }
                    };
                }

                case IntlPaymentActionTypes.CREATE: {
                    return {
                        ...state,
                        inserted: action.payload.data,
                        loading: {
                            create: false
                        }
                    };
                }

                case IntlPaymentActionTypes.UPDATE: {
                    return {
                        ...state,
                        updated: true,
                        loading: {
                            update: false
                        }
                    };
                }

                case IntlPaymentActionTypes.REVIEW: {
                    return {
                        ...state,
                        review: action.payload.data,
                        loading: {
                            review: false
                        }
                    };
                }
                case IntlPaymentActionTypes.CANCEL: {
                    return {
                        ...state,
                        canceled: action.payload.data,
                        order: {
                            ...state.order,
                            status: "canceled"
                        },
                        loading: {
                            cancel: false
                        }
                    };
                }
                case IntlPaymentActionTypes.UPDATE_SETTING: {
                    return {
                        ...state,
                        settingUpdated: action.payload.data.result,
                        loading: {
                            updateSetting: false
                        },
                        trackIds: state.trackIds.filter(e => e !== action.payload.data.trackId)
                    };
                }
                case IntlPaymentActionTypes.GET_SETTING: {
                    return {
                        ...state,
                        setting: action.payload.data,
                        loading: {
                            getSetting: false
                        }
                    };
                }
                default:
                    return { ...state };
            }

        case IntlPaymentActionTypes.API_RESPONSE_ERROR:

            switch (action.payload.actionType) {

                case IntlPaymentActionTypes.ORDER:
                {
                    return {
                        ...state,
                        error: {
                            "order": action.payload.error
                        },
                        loading: {
                            "order": false
                        },
                    };
                }
                case IntlPaymentActionTypes.CREATE:
                {
                    return {
                        ...state,
                        error: {
                            "create": action.payload.error
                        },
                        loading: {
                            "create": false
                        },
                    };
                }
                case IntlPaymentActionTypes.UPDATE:
                {
                    return {
                        ...state,
                        error: {
                            "update": action.payload.error
                        },
                        loading: {
                            "update": false
                        },
                    };
                }
                case IntlPaymentActionTypes.REVIEW:
                {
                    return {
                        ...state,
                        error: {
                            "review": action.payload.error
                        },
                        loading: {
                            "review": false
                        },
                    };
                }
                case IntlPaymentActionTypes.CANCEL:
                {
                    return {
                        ...state,
                        error: {
                            "cancel": action.payload.error
                        },
                        loading: {
                            "cancel": false
                        },
                    };
                }
                case IntlPaymentActionTypes.UPDATE_SETTING:
                {
                    return {
                        ...state,
                        error: {
                            "updateSetting": action.payload.error
                        },
                        loading: {
                            "updateSetting": false
                        },
                        trackIds: state.trackIds.filter(e => e !== action.payload.trackId)
                    };
                }
                case IntlPaymentActionTypes.GET_SETTING:
                {
                    return {
                        ...state,
                        error: {
                            "getSetting": action.payload.error
                        },
                        loading: {
                            "getSetting": false
                        }
                    };
                }
                default:
                    return { ...state };
            }

        case IntlPaymentActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {

                case IntlPaymentActionTypes.ORDER:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "order": action.payload.validationErrors
                        },
                        loading: {
                            "order": false
                        },
                    };
                }
                case IntlPaymentActionTypes.CREATE:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "create": action.payload.validationErrors
                        },
                        loading: {
                            "create": false
                        },
                    };
                }
                case IntlPaymentActionTypes.UPDATE:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "update": action.payload.validationErrors
                        },
                        loading: {
                            "update": false
                        },
                    };
                }
                case IntlPaymentActionTypes.REVIEW:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "review": action.payload.validationErrors
                        },
                        loading: {
                            "review": false
                        },
                    };
                }
                case IntlPaymentActionTypes.CANCEL:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "review": action.payload.validationErrors
                        },
                        loading: {
                            "review": false
                        },
                    };
                }
                case IntlPaymentActionTypes.UPDATE_SETTING:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "updateSetting": action.payload.validationErrors
                        },
                        loading: {
                            "updateSetting": false
                        },
                    };
                }
                case IntlPaymentActionTypes.GET_SETTING:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "getSetting": action.payload.validationErrors
                        },
                        loading: {
                            "getSetting": false
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case IntlPaymentActionTypes.ORDER:
            const { order, ...remainingErrorsForOrder } = state.error as { [key: string]: any };
            return {
                ...state,
                loading: {
                    order: true
                },
                error: remainingErrorsForOrder,
            };

        case IntlPaymentActionTypes.CREATE:
            const { create, ...remainingErrorsForCreate } = state.error as { [key: string]: any };
            return {
                ...state,
                inserted: "",
                loading: {
                    create: true
                },
                error: remainingErrorsForCreate,
            };
        case IntlPaymentActionTypes.UPDATE:
            const { update, ...remainingErrorsForUpdate } = state.error as { [key: string]: any };

            return {
                ...state,
                updated: false,
                loading: {
                    update: true
                },
                error: remainingErrorsForUpdate,
            };

        case IntlPaymentActionTypes.RESET:
            return {
                ...state,
                loading: {},
                error: {},
                validationErrors: {},

                order: {},
                inserted: "",
                updated: false,
                review: {},
                canceled: false,
                settingUpdated: false,
                setting: {}
            };

        case IntlPaymentActionTypes.REVIEW:
            const { review, ...remainingErrorsForReview } = state.error as { [key: string]: any };
            return {
                ...state,
                loading: {
                    review: true
                },
                error: remainingErrorsForReview,
            };

        case IntlPaymentActionTypes.CANCEL:
            const { cancel, ...remainingErrorsForCancel } = state.error as { [key: string]: any };
            return {
                ...state,
                loading: {
                    cancel: true
                },
                error: remainingErrorsForCancel,
            };

        case IntlPaymentActionTypes.UPDATE_SETTING:
            const { updateSetting, ...remainingErrorsForUpdateS } = state.error as { [key: string]: any };
            return {
                ...state,
                settingUpdated: false,
                loading: {
                    updateSetting: true
                },
                error: remainingErrorsForUpdateS,
            };

        case IntlPaymentActionTypes.GET_SETTING:
            const { getSetting, ...remainingErrorsForGetSetting } = state.error as { [key: string]: any };
            return {
                ...state,
                setting: {},
                loading: {
                    getSetting: true
                },
                error: remainingErrorsForGetSetting,
            };

        default:
            return { ...state };
    }
};

export default OrderIntlPayment;
