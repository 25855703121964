import { OrderBillActionTypes } from './constants';
import {isEmptyObject} from "../../helpers/functions";

const INIT_STATE = {
    bill: {},

    bills: {},
    queryParams: {},

    loading: false,
    error: "",
    validationErrors: {},

    searchModal: false,
    tmpBills: {},
    tmpQueryParams: {},
    searchMode: false,
    searchLoading: false,
    searchError: "",
    searchValidationErrors: {},

    exportUrl: "",
    exportLoading: false,
    exportError: "",
    exportValidationErrors: [],
};

type OrderBillData = {
    id: number;
    name: string;
    guard_name: string;
};

type OrderBillQueryParams = {
    limit: number;
    page: number;
    orderBy: string;
    sort: string;
    billNames: string;
};

type OrderBillsResult = {
    bills: OrderBillData | {},
    queryParams: OrderBillQueryParams
}

type OrderBillActionType = {
    type:
        | OrderBillActionTypes.API_RESPONSE_SUCCESS
        | OrderBillActionTypes.API_RESPONSE_ERROR
        | OrderBillActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | OrderBillActionTypes.GET_BILLS
        | OrderBillActionTypes.GET_BILL
        | OrderBillActionTypes.RESET
        | OrderBillActionTypes.EXPORT
        | OrderBillActionTypes.RESET_SEARCH

        | OrderBillActionTypes.OPEN_SEARCH
        | OrderBillActionTypes.CLOSE_SEARCH
        | OrderBillActionTypes.SEARCH_BILL
        | OrderBillActionTypes.CLEAR_SEARCH;
    payload: {
        actionType?: string;
        data?: OrderBillsResult | any;
        error?: string;
        validationErrors?: [];
    };
};

type State = {
    bills?: OrderBillData | {};
    queryParams?: {};

    loading?: boolean;
    value?: boolean;
    tmpBills?: any;
    tmpQueryParams?: any;
};

const OrderBill = (state: State = INIT_STATE, action: OrderBillActionType) => {
    switch (action.type) {
        case OrderBillActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case OrderBillActionTypes.GET_BILLS: {
                    return {
                        ...state,
                        bills: action.payload.data?.bills,
                        queryParams: action.payload.data?.queryParams,
                        loading: false,
                    };
                }
                case OrderBillActionTypes.GET_BILL: {
                    return {
                        ...state,
                        bill: action.payload.data,
                        loading: false,
                    };
                }
                case OrderBillActionTypes.SEARCH_BILL: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpBills: isEmptyObject(state.tmpBills)? state.bills: state.tmpBills,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        bills: action.payload.data?.bills,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                case OrderBillActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportUrl: action.payload.data,
                        exportLoading: false,

                        searchMode: false,
                        searchModal: false,
                        searchLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case OrderBillActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case OrderBillActionTypes.GET_BILL:
                case OrderBillActionTypes.GET_BILLS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case OrderBillActionTypes.SEARCH_BILL: {
                    return {
                        ...state,
                        searchError: action.payload.error,
                        searchLoading: false,
                    };
                }
                case OrderBillActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportError: action.payload.error,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case OrderBillActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case OrderBillActionTypes.GET_BILLS: {
                    return {
                        ...state,
                        error: "Invalid input error",
                        loading: false,
                    };
                }
                case OrderBillActionTypes.GET_BILL: {
                    return {
                        ...state,
                        validationErrors: action.payload.validationErrors,
                        loading: false,
                    };
                }
                case OrderBillActionTypes.SEARCH_BILL: {
                    return {
                        ...state,
                        searchValidationErrors: action.payload.validationErrors,
                        searchLoading: false,
                    };
                }
                case OrderBillActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportValidationErrors: action.payload.validationErrors,
                        exportLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case OrderBillActionTypes.GET_BILLS:
            return {
                ...state,
                // bills: {},
                loading: true,
                error: "",
            };
        case OrderBillActionTypes.GET_BILL:
            return {
                ...state,
                bill: {},
                loading: true,
                error: "",
            };

        case OrderBillActionTypes.RESET:
            return {
                ...state,
                bill: {},

                bills: {},
                queryParams: {},

                loading: false,
                error: "",
                validationErrors: {},

                searchModal: false,
                searchLoading: false,
                searchMode: false,
                searchError: "",
                searchValidationErrors: {},

                tmpBills: {},
                tmpQueryParams: {},

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            };


        // search actions
        case OrderBillActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case OrderBillActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},
            };
        case OrderBillActionTypes.SEARCH_BILL:
            return {
                ...state,
                searchLoading: true,
                searchError: "",
                searchValidationErrors: {},
            };
        case OrderBillActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                bills: state.tmpBills,
                queryParams: state.tmpQueryParams,
                tmpBills: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        case OrderBillActionTypes.EXPORT:

            return {
                ...state,
                exportUrl: "",
                exportLoading: true,
                exportError: "",
                exportValidationErrors: [],
            };

        case OrderBillActionTypes.RESET_SEARCH:

            return {
                ...state,
                searchModal: false,
                searchLoading: false,
                searchError: "",
                searchValidationErrors: {},

                exportUrl: "",
                exportLoading: false,
                exportError: "",
                exportValidationErrors: [],
            }

        default:
            return { ...state };
    }
};

export default OrderBill;
